import React, { Component, useState, useEffect } from 'react'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import Icon from '../../components/Icon.js'
import { withTranslation } from 'react-i18next'
import Camera from '../../components/Camera.js'
import { getToken } from '../../token.js'
import { uploadFile } from '../../connector/connector.js'
import { connect } from 'react-redux'
import reloadPlanning from '../../reloadPlanning.js'
import './AddPicturesToPlanning.css'

class AddPicturesToPlanning extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      takePicture: false,
      pictures: [],
      confirmNewPicture: false
    }

    this.handlePictureSelectClick = this.handlePictureSelectClick.bind(this)
    this.handlePictureCreateClick = this.handlePictureCreateClick.bind(this)
    this.setPicture = this.setPicture.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleConfirmPicture = this.handleConfirmPicture.bind(this)

    this.fileRef = React.createRef()
  }

  handlePictureSelectClick (e) {
    if (this.state.loading) return
    const input = this.fileRef.current
    if (!input) return
    this.fileRef.current.click()
  }

  handlePictureCreateClick (e) {
    if (this.state.loading) return
    this.setState(state => ({ takePicture: true }))
  }

  setPicture (picture) {
    const pictures = [...this.state.pictures]
    pictures.push({
      type: 'new',
      picture
    })
    this.setState(state => ({
      takePicture: false,
      pictures,
      confirmNewPicture: true
    }))
  }

  async handleFileChange (e) {
    if (this.state.loading) return
    const file = e.target.files[0]
    if (!file) return

    this.setState(state => ({ loading: true }))

    const { planning, user } = this.props
    const seqwerk = planning.w_seqwerk
    const seqpers = user.seqpers

    const filename = file.name
    const splitname = filename.split('.')
    const nameWithoutExtension = splitname.slice(0, -1).join('.')
    const finalFilename = [`${nameWithoutExtension}_afronden_${seqwerk}_${seqpers}_${(planning.images || []).length + 1}`, splitname.slice(-1)[0]].join('.')

    await uploadFile({
      file,
      filename: finalFilename,
      seqwerk,
      seqpers,
      token: getToken()
    })

    if (this.isUnmounting) return
    this.setState(state => ({
      loading: false
    }))
    await reloadPlanning()
  }

  async handleConfirmPicture (confirm) {
    if (!confirm) {
      this.setState({
        pictures: [],
        confirmNewPicture: false
      })
    } else {
      const { planning, user } = this.props
      const seqwerk = planning.w_seqwerk
      const seqpers = user.seqpers
      const picture = this.state.pictures[0]
      await uploadFile({
        seqwerk,
        file: picture.picture,
        filename: `afronden_${seqwerk}_${seqpers}_${(planning.images || []).length + 1}.png`,
        seqpers,
        token: getToken()
      })
      await reloadPlanning()
    }
  }

  render () {
    const { t } = this.props
    const { takePicture, confirmNewPicture, pictures } = this.state

    return (
      <>
        { takePicture && <Camera setPicture={this.setPicture} goBack={() => this.setState(state => ({ takePicture: false }))} /> }
        { confirmNewPicture && <ConfirmPicture picture={pictures[0]} t={t} onConfirmPicture={this.handleConfirmPicture} /> }
        <input className="EndJob-file-input" ref={this.fileRef} type="file" onChange={this.handleFileChange} />
        <div className="EndJob-pictures-buttons FinishedJob-pictures-buttons">
          <Button onClick={this.handlePictureSelectClick} color="orange">
            <div className="EndJob-pictures-button">
              <Icon width={20} height={20} name="gallery" fill="white" />
              <Text color="white" weight="bold">{t('choose_picture')}</Text>
            </div>
          </Button>
          <Button onClick={this.handlePictureCreateClick} color="orange">
            <div className="EndJob-pictures-button">
              <Icon width={20} height={20} name="camera" fill="white" />
              <Text color="white" weight="bold">{t('take_picture')}</Text>
            </div>
          </Button>
        </div>
      </>
    )
  }
}

const ConfirmPicture = ({ picture, t, onConfirmPicture }) => {
  const [pictureURL, setPictureURL] = useState('')

  useEffect(() => {
    const fileReader = new FileReader()
    fileReader.addEventListener('loadend', () => {
      setPictureURL(fileReader.result)
    })
    fileReader.readAsDataURL(picture.picture)
  }, [picture])

  return (
    <div className="AddPicturesToPlanning-confirm">
      <div className="AddPicturesToPlanning-confirm-image"><img alt="preview" src={pictureURL} /></div>
      <div className="AddPicturesToPlanning-confirm-buttons">
        <Button onClick={() => onConfirmPicture(false)} color="orange">
          <div className="EndJob-pictures-button">
            <Icon width={20} height={20} name="camera" fill="white" />
            <Text color="white" weight="bold">{t('cancel')}</Text>
          </div>
        </Button>
        <Button onClick={() => onConfirmPicture(true)} color="orange">
          <div className="EndJob-pictures-button">
            <Icon width={20} height={20} name="camera" fill="white" />
            <Text color="white" weight="bold">{t('save')}</Text>
          </div>
        </Button>
      </div>
    </div>
  )
}

const AddPicturesToPlanningWithT = withTranslation()(AddPicturesToPlanning)

function mapStateToProps (state) {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(AddPicturesToPlanningWithT)
