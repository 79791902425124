import React from 'react'
import PropTypes from 'prop-types'
import './CardRow.css'

const CardRow = ({ children, size = 'normal' }) => {
  const classes = [
    'CardRow',
    `CardRow-size-${size}`
  ]
  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}

CardRow.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'normal', 'large', 'grow'])
}

export default CardRow
