import React, { Component } from 'react'
import './AddJobOther.css'
import Header from '../../../components/Header.js'
import Text from '../../../components/Text.js'
import Button from '../../../components/Button.js'
import Input from '../../../components/Input.js'
import Icon from '../../../components/Icon.js'
import history from '../../../history.js'
import JobOtherEditModal from '../../../components/JobOtherEditModal/JobOtherEditModal.js'
import { withTranslation } from 'react-i18next'
import { createWorkOtherCosts } from '../../../connector/connector.js'
import { getToken } from '../../../token.js'
import { connect } from 'react-redux'
import { addMessage } from '../../../components/Messages.js'
import i18n from '../../../i18n.js'
import reloadPlanning from '../../../reloadPlanning.js'
import Loader from '../../../components/Loader.js'

class AddJobOther extends Component {
  constructor (props) {
    super(props)

    this.options = this.getOptions()

    this.state = {
      info: '',
      filteredOptions: [...this.options],
      selectedOption: null,
      loading: false
    }

    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.extra.loading && !this.props.extra.loading && this.props.extra.extra) {
      this.options = this.getOptions()
      this.setState(state => ({ filteredOptions: [...this.options] }))
    }
  }

  getOptions () {
    const extra = this.props.extra
    if (extra.loading || !extra.extra) {
      return []
    }
    const lang = i18n.language
    return extra.extra.filter(item => item.soort === 'A').filter(item => item.language.toLowerCase() === lang).map(item => ({
      label: item.omschrijving,
      seqextra: item.seqextra
    }))
  }

  handleSearchChange (e) {
    // find first word match
    // then add other matches below

    // example search: 'water option'
    const newValue = e.target.value
    const options = this.options
    if (!newValue) return this.setState(state => ({ filteredOptions: [...options] }))
    const splitSearch = newValue.split(' ')

    // find all options that start with first word in search
    // example: ['water', 'water foo', 'water bar', 'water option']
    const firstWordRegExp = new window.RegExp('^' + splitSearch[0], 'i')
    const matchingFirstWord = options.filter(option => firstWordRegExp.test(option.label))

    // find all options that have the first word in search in any position but the first
    // and combine with previous results
    // this way the first results are sorted FIRST and the seconds results are secondary results
    // example: ['bar water', 'baz water option', 'foo water']
    // combined: ['water', 'water foo', 'water bar', 'water option', 'bar water', 'baz water option', 'foo water']
    const notFirstWordRegExp = new window.RegExp(' ' + splitSearch[0], 'i')
    const matchingFirstSearch = [...matchingFirstWord, ...options.filter(option => notFirstWordRegExp.test(option.label))]

    // refine options to only include ones that match other words in the search as well
    // example result: ['water option', 'baz water option']
    // and NOT (note alphabetical order): ['baz water option', 'water option']
    const allMatches = splitSearch.slice(1).reduce((accumulator, currentValue) => {
      if (!currentValue) return accumulator
      const regExp = new window.RegExp(' ' + currentValue, 'i')
      return accumulator.filter(option => regExp.test(option.label))
    }, matchingFirstSearch)

    this.setState(state => ({ filteredOptions: allMatches }))
  }

  handleOptionClick (option) {
    return e => {
      this.setState(state => ({ selectedOption: option }))
    }
  }

  handleCancel (e) {
    this.setState(state => ({ selectedOption: null }))
  }

  async handleSave ({ comment, amount }) {
    const workOtherCosts = {
      seqwerk: this.props.planning.w_seqwerk,
      seqextra: this.state.selectedOption.seqextra,
      opmerking: comment,
      aantal: amount
    }

    this.setState(state => ({ loading: true }))

    try {
      await createWorkOtherCosts({
        workOtherCosts,
        token: getToken()
      })
      await reloadPlanning()
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
    }

    addMessage({
      type: 'info',
      message: this.props.t('other_costs_added')
    })
    history.go(-2)
  }

  render () {
    const t = this.props.t
    const {
      filteredOptions,
      selectedOption,
      loading
    } = this.state
    return (
      <div>
        <Header backButton={true} shadow={true} size="small">
          <div className="AJO-header">
            <Text size="large" weight="bold" color="dark">{t('add_other_costs')}</Text>
            <Button onClick={() => history.go(-2)}><Text>{t('cancel')}</Text></Button>
          </div>
        </Header>
        <div className="AJO-scroll">
          {loading && <Loader fullPage={true} />}
          <div className="AJO-search">
            <div className="AJO-search-icon"><Icon name="search" fill="var(--grey-dark)" /></div>
            <Input onChange={this.handleSearchChange} />
          </div>
          {
            filteredOptions.map(option => {
              return (
                <div key={option.seqextra} className="AJO-option" onClick={this.handleOptionClick(option)}>
                  <Text color="dark">{option.label}</Text>
                </div>
              )
            })
          }
        </div>
        { !loading && selectedOption && <JobOtherEditModal onCancel={this.handleCancel} onSave={this.handleSave} option={selectedOption} /> }
      </div>
    )
  }
}

const AddJobOtherWithT = withTranslation()(AddJobOther)

function mapStateToProps (state, props) {
  const seqplan = props.seqplan
  const planning = (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan)
  return {
    extra: state.extra,
    planning
  }
}

const AddJobOtherConnect = connect(mapStateToProps)(AddJobOtherWithT)

export default AddJobOtherConnect
