function setWorkProgressLoading (id) {
  return {
    type: 'SET_WORK_PROGRESS_LOADING',
    id
  }
}
function setWorkProgress (id, progress) {
  return {
    type: 'SET_WORK_PROGRESS',
    id,
    progress
  }
}
function setWorkProgressError (id, error) {
  return {
    type: 'SET_WORK_PROGRESS_ERROR',
    id,
    error
  }
}

export {
  setWorkProgressLoading,
  setWorkProgress,
  setWorkProgressError
}
