function setExtraPositionsLoading () {
  return {
    type: 'SET_EXTRA_POSITIONS_LOADING'
  }
}

function setExtraPositions (extraPositions) {
  return {
    type: 'SET_EXTRA_POSITIONS',
    extraPositions
  }
}

function setExtraPositionsError (error) {
  return {
    type: 'SET_EXTRA_POSITIONS_ERROR',
    error
  }
}

export {
  setExtraPositionsLoading,
  setExtraPositions,
  setExtraPositionsError
}
