import React, { Component } from 'react'
import './Planning.css'
import Header from '../../components/Header.js'
import Icon from '../../components/Icon.js'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import WorkItem from './WorkItem.js'
import { connect } from 'react-redux'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'
import { format, startOfDay } from 'date-fns'
import { setJobTab as setJobTabAction } from '../../redux/jobPage/actions.js'
import ReactSwipe from 'react-swipe'
import FinishedWorkItem from './FinishedWorkItem.js'
import convertTimezone from '../../convertTimezone.js'
import getPlanningRange from '../../getPlanningRange.js'

class Planning extends Component {
  constructor (props) {
    super(props)

    this.swipeRef = React.createRef()

    this.handleNavClick = this.handleNavClick.bind(this)
    this.handleCardClick = this.handleCardClick.bind(this)
  }

  handleMenuClick (e) {
    history.push('/menu')
  }

  handleNavClick (change) {
    return e => {
      const swipeEl = this.swipeRef.current
      if (!swipeEl) return
      if (change === 'previous') {
        return swipeEl.prev()
      }
      if (change === 'next') {
        return swipeEl.next()
      }
    }
  }

  handleCardClick (planning) {
    return e => {
      this.props.setJobTab('info')
      history.push(`/job/${planning.p_seqplan}`)
    }
  }

  render () {
    const t = this.props.t
    const planningList = this.props.planning.planning || []
    const sortedPlanningList = [...planningList].sort((a, b) => {
      let num = 0
      const aStart = new Date(a.p_van).getTime()
      const bStart = new Date(b.p_van).getTime()
      if (aStart < bStart) num = -1
      if (aStart > bStart) num = 1
      return num
    })

    const { dates } = getPlanningRange(7)
    const dateNames = ['today', 'tomorrow', 'dayAfterTomorrow', '3daysFromNow', '4daysFromNow', '5daysFromNow', '6daysFromNow', '7daysFromNow']
    const planningObjectList = []

    dates.forEach((date, index) => {
      planningObjectList[index] = {
        date,
        planning: sortedPlanningList.filter(planning => {
          const planningVanDate = convertTimezone(new Date(planning.p_van))
          return startOfDay(planningVanDate).getTime() === date
        }),
        name: dateNames[index]
      }
    })

    const finishedList = []
    const unfinishedList = []
    planningObjectList[0].planning.forEach(item => {
      if (item.p_uitgevoerd) {
        finishedList.push(item)
      } else {
        unfinishedList.push(item)
      }
    })

    return (
      <div className="Planning">
        <Header color="dark">
          <div className="Planning-header">
            <div className="Planning-header-icon">
              <Button onClick={this.handleMenuClick}>
                <Icon name="menuHamburger" width={18} height={18} fill="white" />
              </Button>
            </div>
            <div className="Planning-header-title">
              <Text size="large" weight="bold" color="white">{t('my_planning')}</Text>
            </div>
          </div>
        </Header>
        <ReactSwipe
          className="Planning-swipe"
          swipeOptions={{
            continuous: false
          }}
          ref={this.swipeRef}
        >
          <div className="Planning-scroll">
            <div className="Planning-nav">
              <div className="Planning-nav-date">
                <Text weight="bold" color="dark">{t('today')}</Text>
                <Text color="dark">{format(planningObjectList[0].date, 'dd/MM')}</Text>
              </div>
              <div className="Planning-nav-button">
                <Button onClick={this.handleNavClick('next')}>
                  <Icon name="caretRight" width={12} height={12} fill="var(--grey-dark)" />
                </Button>
              </div>
            </div>
            <div className="Planning-rows">
              { finishedList.map(planning =>
                <FinishedWorkItem key={planning.p_seqplan} planning={planning} onClick={this.handleCardClick(planning)} />
              )}
              { unfinishedList.map(planning =>
                <WorkItem key={planning.p_seqplan} planning={planning} onClick={this.handleCardClick(planning)} />
              )}
            </div>
          </div>
          {
            planningObjectList.map((dayPlanning, index) => {
              if (index === 0) return null
              return (
                <div className="Planning-scroll" key={'day' + index}>
                  <PlanningTab title={t(dayPlanning.name)} list={dayPlanning.planning} onNavClick={this.handleNavClick} onCardClick={this.handleCardClick} date={dayPlanning.date} />
                </div>
              )
            })
          }
        </ReactSwipe>
      </div>
    )
  }
}

const PlanningTab = ({ list, onNavClick, onCardClick, date, title }) => {
  return (
    <>
      <div className="Planning-nav">
        <div className="Planning-nav-date">
          <Text weight="bold" color="dark">{title}</Text>
          <Text color="dark">{format(date, 'dd/MM')}</Text>
        </div>
        <div className="Planning-nav-button Planning-nav-button-left">
          <Button onClick={onNavClick('previous')}>
            <Icon name="caretLeft" width={12} height={12} fill="var(--grey-dark)" />
          </Button>
        </div>
        <div className="Planning-nav-button Planning-nav-button-right">
          <Button onClick={onNavClick('next')}>
            <Icon name="caretRight" width={12} height={12} fill="var(--grey-dark)" />
          </Button>
        </div>
      </div>
      <div className="Planning-rows">
        { list.map(planning =>
          <WorkItem key={planning.p_seqplan} planning={planning} onClick={onCardClick(planning)} />
        )}
      </div>
    </>
  )
}

function mapStateToProps (state) {
  return {
    planning: state.planning
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setJobTab: tab => dispatch(setJobTabAction(tab))
  }
}

const PlanningWithT = withTranslation()(Planning)
const PlanningConnect = connect(mapStateToProps, mapDispatchToProps)(PlanningWithT)

export default PlanningConnect
