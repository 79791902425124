import React, { Component } from 'react'
import './Menu.css'
import Header from '../../components/Header.js'
import Icon from '../../components/Icon.js'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import history from '../../history.js'
import { removeToken } from '../../token.js'
import { withTranslation } from 'react-i18next'
import { reduxLogout } from '../../redux/store.js'
import { connect } from 'react-redux'
import { version } from '../../../package.json'

class Menu extends Component {
  handleLanguageClick (e) {
    history.push('/changeLanguage')
  }

  handleLogoutClick (e) {
    removeToken()
    reduxLogout()
    history.push('/login')
  }

  render () {
    const { t, user } = this.props
    const userObject = user.user || {}
    const selectedLanguage = t('dutch')
    return (
      <div className="Menu">
        <Header color="very-dark" size="small">
          <div className="Menu-header">
            <div className="Menu-header-close">
              <Button onClick={history.goBack}>
                <Icon name="cross" fill="white" width={22} height={22} />
              </Button>
            </div>
            <Text color="white" weight="bold" size="large">{userObject.voornaam || ''}</Text>
          </div>
        </Header>
        <div className="Menu-content">
          <div className="Menu-language"><Button onClick={this.handleLanguageClick}><Text color="white" size="small">{t('language')}: {selectedLanguage}</Text></Button></div>
          <div className="Menu-logout"><Button onClick={this.handleLogoutClick}><Text color="white" size="small">{t('log_out')}</Text></Button></div>
          <div className="Menu-image"><img alt="Bjorn Mens logo" src="logo_small.png" /></div>
          <div className="Menu-version"><Text color="white" size="small">{version}</Text></div>
        </div>
      </div>
    )
  }
}

const MenuWithT = withTranslation()(Menu)

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(MenuWithT)
