import React, { useState } from 'react'
import Text from '../Text.js'
import Input from '../Input.js'
import Textarea from '../Textarea.js'
import Button from '../Button.js'
import { useTranslation } from 'react-i18next'
import './JobOtherEditModal.css'

const JobOtherEditModal = ({ option, onCancel, onSave, edit }) => {
  const { label, comment, amount } = option
  const [newComment, setComment] = useState(comment || '')
  const [newAmount, setAmount] = useState(amount || '1')
  const { t } = useTranslation()
  return (
    <div className="AJO-popover">
      <div className="AJO-container">
        <div className="AJO-popover-head"><Text color="dark">{label}</Text></div>
        <div className="AJO-popover-input">
          <Text color="dark" size="small">{t('amount')}</Text>
          <Input size="small" onChange={e => setAmount(e.target.value)} value={newAmount} />
        </div>
        <div className="AJO-popover-input">
          <Text color="dark" size="small">{t('comment')}</Text>
          <Textarea onChange={e => setComment(e.target.value)} value={newComment} />
        </div>
        <div className="AJO-popover-buttons">
          <Button color="red" onClick={onCancel}>{t('cancel')}</Button>
          <Button color="orange" onClick={() => onSave({ comment: newComment, amount: ~~newAmount || 1 })}>{t(edit ? 'save' : 'add')}</Button>
        </div>
      </div>
    </div>
  )
}

export default JobOtherEditModal
