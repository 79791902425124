import React, { Component } from 'react'
import './AddJobDrill.css'
import { connect } from 'react-redux'
import Header from '../../../components/Header.js'
import Icon from '../../../components/Icon.js'
import Text from '../../../components/Text.js'
import Button from '../../../components/Button.js'
import Input from '../../../components/Input.js'
import Label from '../../../components/Label.js'
import Dropdown from '../../../components/dropdown/Dropdown.js'
import Checkbox from '../../../components/Checkbox.js'
import Loader from '../../../components/Loader.js'
import history from '../../../history.js'
import { withTranslation } from 'react-i18next'
import { createWork, updateWork, createWorkExtra, deleteWorkExtra } from '../../../connector/connector.js'
import reloadPlanning from '../../../reloadPlanning.js'
import { getToken } from '../../../token.js'
import { addMessage } from '../../../components/Messages.js'
import i18n from '../../../i18n.js'

class AddJobDrill extends Component {
  constructor (props) {
    super(props)

    let editState = {}
    if (props.edit && props.werkDetail) {
      editState = this.createState(props.werkDetail)
    }

    this.state = {
      amount: '',
      type: {},
      diameter: '',
      depth: '',
      extra: new Set(),
      loading: false,
      duplicateInsteadOfEdit: false,
      ...editState
    }

    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleDuplicateClick = this.handleDuplicateClick.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    let ok = false
    if (prevProps.werkDetail !== this.props.werkDetail) {
      ok = true
    }
    if (prevProps.loading !== this.props.loading) {
      ok = true
    }
    if (this.state.duplicateInsteadOfEdit) {
      ok = false
    }
    if (ok && !this.props.loading) {
      this.setState(state => this.createState(this.props.werkDetail))
    }
  }

  createState (detail) {
    if (!detail) return {}
    const extra = detail.extra || []
    const extraSet = new Set(extra.map(item => {
      const extras = this.props.extra || []
      return extras.find(e => e.seqextra === item.seqtoeslag)
    }))
    return {
      amount: detail.det_aantal,
      type: this.props.positions.find(position => position.value === detail.pos_seqpos) || {},
      diameter: detail.det_diameter,
      depth: detail.det_diepte,
      extra: extraSet
    }
  }

  handleInputChange (name) {
    return e => {
      const newValue = e.target.value
      this.setState(state => ({ [name]: newValue }))
    }
  }

  handleTypeChange (newValue) {
    this.setState(state => ({ type: newValue }))
  }

  handleExtraChange (option) {
    return newValue => {
      const extra = new Set(this.state.extra)
      if (newValue) extra.add(option)
      else extra.delete(option)
      this.setState(state => ({ extra }))
    }
  }

  handleAddClick () {
    if (this.state.loading || this.props.loading) return
    const edit = this.props.edit
    if (edit && !this.state.duplicateInsteadOfEdit) {
      this.editJob()
    } else {
      this.createJob()
    }
  }

  async handleDuplicateClick () {
    if (this.state.loading || this.props.loading) return
    const edit = this.props.edit
    if (edit) {
      this.editOrDuplicateJob()
    } else {
      this.duplicateJob()
    }
  }

  async createJob () {
    this.setState(state => ({ loading: true }))
    const ok = await this.postJob()
    if (!ok) {
      return this.setState(state => ({ loading: false }))
    }
    if (this.state.duplicateInsteadOfEdit) { // go back one
      history.goBack()
    } else { // nav to addJobProgress
      const seqplan = this.props.planning.p_seqplan
      const detailId = ok.insertId
      history.push(`/job/${seqplan}/addJobProgress/${detailId}?newDetail=true`)
    }
  }

  async duplicateJob () {
    this.setState(state => ({ loading: true }))
    await this.postJob()
    this.setState(state => ({ loading: false }))
  }

  async editJob () {
    this.setState(state => ({ loading: true }))
    const ok = await this.patchJob()
    if (!ok) {
      return this.setState(state => ({ loading: false }))
    }
    history.go(-1)
  }

  async editOrDuplicateJob () {
    this.setState(state => ({ loading: true }))
    if (this.state.duplicateInsteadOfEdit) {
      await this.postJob()
    } else {
      const ok = await this.patchJob()
      if (!ok) {
        return this.setState(state => ({ loading: false }))
      }
    }
    this.setState(state => ({ loading: false, duplicateInsteadOfEdit: true }))
  }

  async patchJob () {
    const {
      planning,
      werkDetail: currentJob,
      extra: allExtra,
      extraPositions,
      user
    } = this.props
    const state = this.state
    const update = {}
    const token = getToken()

    const names = [['amount', 'det_aantal', 'aantal'], ['diameter', 'det_diameter', 'diameter'], ['depth', 'det_diepte', 'diepte']]
    names.forEach(name => {
      if (currentJob[name[1]] !== parseFloat(state[name[0]])) {
        update[name[2]] = parseFloat(state[name[0]])
      }
    })

    const currentType = currentJob.pos_seqpos
    const newType = state.type || {}
    let myPos = currentType
    if (currentType !== newType && newType.value) {
      update.seqpos = newType.value
      myPos = newType.value
    }

    let workPromise
    if (Object.keys(update).length) {
      workPromise = updateWork({
        id: currentJob.det_id,
        update,
        token
      })
    }

    const availableExtra = new Set(extraPositions.filter(extraPos => extraPos.seqpos === myPos).map(item => item.seqextra))
    const filteredNewExtra = Array.from(state.extra).filter(extra => availableExtra.has(extra.seqextra))

    const extraPromises = []
    const currentExtra = new Map(currentJob.extra.map(extra => [extra.seqtoeslag, extra]))
    const newExtra = new Map(filteredNewExtra.map(item => [item.seqextra, item]))
    newExtra.forEach((item, key) => {
      if (!currentExtra.get(key)) {
        // create
        const extraObj = allExtra.find(obj => obj.seqextra === key)
        extraPromises.push(createWorkExtra({
          extra: {
            id_t_werk_detail: currentJob.det_id,
            seqwerk: planning.w_seqwerk,
            seqtoeslag: extraObj.seqextra,
            omschrijving_toeslag: extraObj.omschrijving,
            creusr: user.seqpers,
            seqwerkdet: currentJob.det_seqwerkdet
          },
          token
        }))
      }
    })
    currentExtra.forEach((item, key) => {
      if (!newExtra.get(key)) {
        // delete
        extraPromises.push(deleteWorkExtra({
          id: item.id,
          token
        }))
      }
    })

    try {
      await Promise.all([
        workPromise,
        ...extraPromises
      ])
      await reloadPlanning()
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return false
    }

    addMessage({
      type: 'info',
      message: this.props.t('job_edited')
    })
    return true
  }

  async postJob () {
    const {
      planning,
      user,
      extraPositions
    } = this.props
    const {
      amount,
      diameter,
      depth,
      type = {},
      extra
    } = this.state
    const newJob = {
      seqwerk: planning.w_seqwerk,
      seqpos: type.value || 0,
      aantal: ~~amount,
      diameter: ~~diameter,
      diepte: parseFloat(depth),
      typewerk: 'B'
    }

    // remove any 'extra' items that can't be added to this type
    const myPos = newJob.seqpos
    const availableExtra = new Set(extraPositions.filter(extraPos => extraPos.seqpos === myPos).map(item => item.seqextra))
    const filteredExtra = [...extra].filter(extra => availableExtra.has(extra.seqextra))

    const token = getToken()
    let newJobResult
    try {
      newJobResult = await createWork({ work: newJob, token })
      const extraPromises = filteredExtra.map(item => {
        return createWorkExtra({
          extra: {
            id_t_werk_detail: newJobResult.insertId,
            seqwerk: planning.w_seqwerk,
            seqtoeslag: item.seqextra,
            omschrijving_toeslag: item.omschrijving,
            creusr: user.seqpers
          },
          token
        })
      })
      await Promise.all(extraPromises)
      await reloadPlanning()
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return false
    }
    addMessage({
      type: 'info',
      message: this.props.t('new_job_added', { details: `${newJob.aantal || '?'} ${type.label || '?'} ${newJob.diameter || '?'} ${newJob.diepte || '?'}` })
    })
    return newJobResult
  }

  render () {
    const {
      t,
      edit,
      extra,
      extraPositions,
      positions,
      werkDetail
    } = this.props
    const {
      amount,
      type,
      diameter,
      depth,
      extra: selectedExtras,
      duplicateInsteadOfEdit
    } = this.state
    const loading = this.props.loading || this.state.loading
    const title = edit ? `${werkDetail.det_aantal || '?'} ${werkDetail.pos_positie_code || '?'} ${werkDetail.det_diameter || '?'} ${werkDetail.det_diepte || '?'}` : t('add_job_drill')
    const lang = i18n.language
    const filteredLangauge = extra.filter(extra => extra.language.toLowerCase() === lang)
    const myPos = type.value || werkDetail.pos_seqpos
    const filteredExtraPositions = extraPositions.filter(extraPos => extraPos.seqpos === myPos)
    const filteredExtra = filteredExtraPositions.map(pos => {
      return filteredLangauge.find(extra => extra.seqextra === pos.seqextra)
    }).filter(extra => extra)
    filteredExtra.sort((a, b) => {
      let num = 0
      const lowerCaseA = a.omschrijving.toLowerCase()
      const lowerCaseB = b.omschrijving.toLowerCase()
      if (lowerCaseA < lowerCaseB) num = -1
      if (lowerCaseA > lowerCaseB) num = 1
      return num
    })
    return (
      <div className="AJD">
        <Header size="small" color="purple" backButton={true}>
          <div className="AJD-header">
            <Icon name="drill" fill="white" width={22} height={22} />
            <Text color="white" size="large" weight="bold">{title}</Text>
            <Button onClick={() => history.go(edit ? -1 : -2)}><Text color="white">{t('cancel')}</Text></Button>
          </div>
        </Header>
        <div className="AJD-body">
          {loading && <Loader fullPage={true} />}
          <div className="AJD-scroll">
            <div className="AJD-inputs">
              <div className="AJD-input-row">
                <div className="AJD-input">
                  <div className="AJD-input-icon"><Icon name="poundSign" /></div>
                  <div className="AJD-input-field"><Input type="number" value={amount} onChange={this.handleInputChange('amount')} size="small" /></div>
                  <div className="AJD-input-fill"></div>
                </div>
                <div>
                  <Dropdown
                    options={positions}
                    icon="box"
                    onChange={this.handleTypeChange}
                    value={type || {}}
                  />
                </div>
              </div>
              <div className="AJD-input-row">
                <div className="AJD-input">
                  <div className="AJD-input-icon"><Icon name="diameter" /></div>
                  <div className="AJD-input-field"><Input type="number" value={diameter} onChange={this.handleInputChange('diameter')} size="small" /></div>
                  <div className="AJD-input-fill"><Text size="small" color="dark">mm</Text></div>
                </div>
                <div className="AJD-input">
                  <div className="AJD-input-icon"><Icon name="depth" /></div>
                  <div className="AJD-input-field"><Input type="number" value={depth} onChange={this.handleInputChange('depth')} size="small" /></div>
                  <div className="AJD-input-fill"><Text size="small" color="dark">cm</Text></div>
                </div>
              </div>
            </div>
            <div className="AJD-extra">
              {
                filteredExtra.map(option => {
                  const myId = 'id-checkbox' + option.seqextra
                  return (
                    <div key={option.seqextra} className="AJD-extra-row">
                      <div className="AJD-extra-checkbox"><Checkbox id={myId} checked={selectedExtras.has(option)} onChange={this.handleExtraChange(option)} /></div>
                      <div className="AJD-extra-checkbox-label">
                        <Label htmlFor={myId}>
                          <Text color="dark">{option.omschrijving}</Text>
                        </Label>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="AJD-buttons">
            <Button color="purple-medium" onClick={this.handleAddClick}>
              <div className="AJD-button">
                <div className="AJD-button-icon"><Icon name={edit ? 'check' : 'add'} fill="white" width={20} height={20} /></div>
                <div className="AJD-button-text"><Text color="white" weight="bold">{t(edit && !duplicateInsteadOfEdit ? 'save' : 'add')}</Text></div>
              </div>
            </Button>
            <Button color="purple" onClick={this.handleDuplicateClick}>
              <div className="AJD-button">
                <div className="AJD-button-icon"><Icon name="duplicate" fill="white" width={20} height={20} /></div>
                <div className="AJD-button-text"><Text color="white" weight="bold">{t(edit && !duplicateInsteadOfEdit ? 'save_and_duplicate' : 'add_and_duplicate')}</Text></div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const AddJobDrillWithT = withTranslation()(AddJobDrill)

function mapStateToProps (state, props) {
  const seqplan = props.seqplan
  const id = props.id
  const planning = (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan) || { werk_detail: [] }
  const werkDetail = planning.werk_detail.find(detail => detail.det_id === id) || {}
  const tExtra = state.extra.extra || []
  const extra = tExtra.filter(item => item.soort === 'E')
  const extraPositions = state.extraPositions.extraPositions || []

  const loading = state.planning.loading || state.extra.loading || state.extraPositions.loading || state.positions.loading || state.user.loading

  return {
    loading,
    planning,
    werkDetail,
    extra,
    extraPositions,
    positions: createTypeOptions(state.positions.positions || []),
    user: state.user.user
  }
}

function createTypeOptions (positions) {
  return positions.filter(position => (position.typewerk || '').toUpperCase() === 'B').map(position => ({
    value: position.seqpos,
    label: position.positie_code
  }))
}

const AddJobDrillConnect = connect(mapStateToProps)(AddJobDrillWithT)

export default AddJobDrillConnect
