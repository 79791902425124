import { getExtra as fetchExtra } from './connector.js'
import store from '../redux/store.js'
import { setExtraLoading, setExtra, setExtraError } from '../redux/extra/actions.js'
import { validLanguages } from '../i18n.js'

async function getExtra ({ token }) {
  const state = store.getState()
  if (state.extra.loading) return
  store.dispatch(setExtraLoading())
  try {
    let extra = await fetchExtra({ token })
    extra.sort((a, b) => {
      let num = 0
      const lowerCaseA = a.omschrijving.toLowerCase()
      const lowerCaseB = b.omschrijving.toLowerCase()
      if (lowerCaseA < lowerCaseB) num = -1
      if (lowerCaseA > lowerCaseB) num = 1
      return num
    })
    extra = fixLanguages(extra)
    store.dispatch(setExtra(extra))
  } catch (error) {
    console.error('error', error)
    store.dispatch(setExtraError(error))
  }
}

export default getExtra

function fixLanguages (extras) {
  return extras.map(extra => {
    if (validLanguages.has((extra.language || '').toLowerCase())) return extra
    return {
      ...extra,
      language: 'nl'
    }
  })
}
