function setJobTab (tab) {
  return {
    type: 'SET_JOB_TAB',
    tab
  }
}

export {
  setJobTab
}
