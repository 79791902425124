import React from 'react'
import PropTypes from 'prop-types'
import './Button.css'

const Button = ({ children, color = 'transparent', weight = 'bold', title, ...props }) => {
  const buttonTitle = title || typeof children === 'string' ? children : ''
  const classes = [
    'Button',
    `Button-color-${color}`,
    `Button-weight-${weight}`
  ]
  return (
    <button
      title={buttonTitle}
      className={classes.join(' ')}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['transparent', 'orange', 'red', 'green', 'grey', 'dark', 'purple', 'purple-medium', 'blue', 'blue-medium']),
  weight: PropTypes.oneOf(['bold', 'normal']),
  title: PropTypes.string
}

export default Button
