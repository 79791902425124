import getPlanning from './connector/getPlanning.js'
import { getToken } from './token.js'
import store from './redux/store.js'
import getPlanningRange from './getPlanningRange.js'

function reloadPlanning () {
  const user = store.getState().user || {}
  if (!user.user || user.loading) return
  const { start, end } = getPlanningRange(7)
  return getPlanning({
    start,
    end,
    seqpers: user.user.seqpers,
    token: getToken()
  })
}

export default reloadPlanning
