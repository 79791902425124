import React from 'react'
import Login from './pages/login/Login.js'
import Planning from './pages/planning/Planning.js'
import Job from './pages/job/Job.js'
import Comment from './pages/comment/Comment.js'
import AddJobProgress from './pages/addJobProgress/AddJobProgress.js'
import AddJob from './pages/addJob/AddJob.js'
import AddJobDrill from './pages/addJob/drill/AddJobDrill.js'
import AddJobSaw from './pages/addJob/saw/AddJobSaw.js'
import AddJobOther from './pages/addJob/other/AddJobOther.js'
import EndJob from './pages/endJob/EndJob.js'
import Menu from './pages/menu/Menu.js'
import ChangeLanguage from './pages/changeLanguage/ChangeLanguage.js'
import Image from './pages/image/Image.js'

const routes = [
  {
    path: '/',
    action: () => <Planning />
  },
  {
    path: '/login',
    action: () => <Login />
  },
  {
    path: '/job/:jobId',
    action: (context, params) => <Job seqplan={~~params.jobId} />
  },
  {
    path: '/job/:jobId/comment',
    action: (context, params) => <Comment seqplan={~~params.jobId} />
  },
  {
    path: '/job/:jobId/editComment/:commentId',
    action: (context, params) => <Comment edit={true} seqplan={~~params.jobId} seqopm={~~params.commentId} />
  },
  {
    path: '/job/:jobId/addJobProgress/:detailId',
    action: (context, params) => <AddJobProgress seqplan={~~params.jobId} id={~~params.detailId} />
  },
  {
    path: '/job/:jobId/addJob',
    action: (context, params) => <AddJob seqplan={~~params.jobId} />
  },
  {
    path: '/job/:jobId/editJob/:detailId',
    action: (context, params) => <AddJobDrill edit={true} seqplan={~~params.jobId} id={~~params.detailId} />
  },
  {
    path: '/job/:jobId/editJobSaw/:detailId',
    action: (context, params) => <AddJobSaw edit={true} seqplan={~~params.jobId} id={~~params.detailId} />
  },
  {
    path: '/job/:jobId/addJobDrill',
    action: (context, params) => <AddJobDrill seqplan={~~params.jobId} />
  },
  {
    path: '/job/:jobId/addJobSaw',
    action: (context, params) => <AddJobSaw seqplan={~~params.jobId} />
  },
  {
    path: '/job/:jobId/addJobOther',
    action: (context, params) => <AddJobOther seqplan={~~params.jobId} />
  },
  {
    path: '/job/:jobId/endJob',
    action: (context, params) => <EndJob seqplan={~~params.jobId} />
  },
  {
    path: '/menu',
    action: () => <Menu />
  },
  {
    path: '/changeLanguage',
    action: () => <ChangeLanguage />
  },
  {
    path: '/image/:seqplan/:seqafb',
    action: (context, params) => <Image seqplan={~~params.seqplan} seqafb={~~params.seqafb} />
  }
]

export default routes
