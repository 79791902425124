import React, { Component } from 'react'
import './ChangeLanguage.css'
import Header from '../../components/Header.js'
import Icon from '../../components/Icon.js'
import Text from '../../components/Text.js'
import { withTranslation } from 'react-i18next'
import i18n from '../../i18n.js'

const options = [
  {
    value: 'nl',
    label: 'Nederlands'
  }
  // ,
  // {
  //   value: 'en',
  //   label: 'English'
  // }
]

class ChangeLanguage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      current: options[0]
    }
  }

  handleLanguageChange (newValue) {
    return e => {
      this.setState(state => ({ current: newValue }))
      i18n.changeLanguage(newValue.value)
    }
  }

  render () {
    const t = this.props.t
    const current = this.state.current
    return (
      <div className="Language">
        <Header color="very-dark" size="small" backButton={true}>
          <div className="Language-header">
            <Text color="white" weight="bold" size="large">{t('choose_language')}</Text>
          </div>
        </Header>
        <div>
          {
            options.map(language => {
              const isSelected = language.value === current.value
              return (
                <div key={language.value} onClick={this.handleLanguageChange(language)} className="Language-row">
                  <div className="Language-row-text"><Text color="white" size="small" weight={isSelected ? 'bold' : 'normal'}>{language.label}</Text></div>
                  {isSelected && <div className="Language-row-icon"><Icon name="check" fill="white" /></div>}
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

const ChangeLanguageWithT = withTranslation()(ChangeLanguage)

export default ChangeLanguageWithT
