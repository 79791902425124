import React, { Component } from 'react'
import { getFile } from '../connector/connector.js'
import { getToken } from '../token.js'
import { addMessage } from '../components/Messages.js'
import Text from './Text.js'
import PropTypes from 'prop-types'

class LoadImage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      image: ''
    }
  }

  componentDidMount () {
    this.fetchImage()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.seqafb !== this.props.seqafb) {
      this.fetchImage()
    }
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  async fetchImage () {
    let image
    try {
      let blob
      if (this.props.blob) {
        blob = this.props.blob
      } else {
        const response = await getFile({
          seqafb: this.props.seqafb,
          thumbnail: !this.props.large,
          token: getToken()
        })
        blob = await response.blob()
      }
      image = window.URL.createObjectURL(blob)
    } catch (error) {
      addMessage({
        type: 'error',
        message: 'Afbeelding kon niet geladen worden.'
      })
    }
    if (this.isUnmounting) return
    this.setState(state => ({ image }))
  }

  render () {
    const { image } = this.state
    if (!image) return <Text size="small">Afbeelding niet beschikbaar</Text>
    return <img src={image} alt="" />
  }
}

LoadImage.propTypes = {
  seqafb: PropTypes.number,
  large: PropTypes.bool
}

export default LoadImage
