import ky from 'ky'
import { addDays, startOfDay, format } from 'date-fns'
import convertTimezone from '../convertTimezone.js'

// const host = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:8080'
const host = process.env.NODE_ENV === 'production' ? window.location.origin : 'https://bmapp.connbex.be'
const api = '/api/v1/'

async function getMany (url, options = {}, previous = []) {
  options.searchParams = options.searchParams || {}
  const response = await ky.get(url, options).json()
  if (response.length) {
    return getMany(url, {
      ...options,
      searchParams: {
        ...options.searchParams,
        _p: (options.searchParams._p || 0) + 1
      }
    }, [...previous, ...response])
  } else {
    return previous
  }
}

function getPlanning ({ start, end, seqpers, token }) {
  const url = host + api + 'getPlanning'
  return ky.get(url, {
    searchParams: {
      start,
      end,
      seqpers
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

async function updatePlanning ({ seqpers, seqwerk, van, update, token }) {
  van = convertTimezone(new Date(van))
  const vanStart = format(startOfDay(van), 'yyyy-MM-dd')
  const vanEnd = format(addDays(startOfDay(van), 1), 'yyyy-MM-dd')
  const getUrl = host + api + `mysql/planning?_where=(seqpers,eq,${seqpers})~and(seqwerk,eq,${seqwerk})~and(van,bw,${vanStart},${vanEnd})`
  const response = (await ky.get(getUrl, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json())[0] || {}
  const url = host + api + 'mysql/planning/' + response.seqplan
  return ky.patch(url, {
    json: update,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function getUser ({ seqpers, token }) {
  const url = host + api + 'mysql/t_logins'
  return ky.get(url, {
    searchParams: {
      _where: `(seqpers,eq,${seqpers})`,
      _fields: '-pass'
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function getExtra ({ token }) {
  const url = host + api + 'mysql/t_extra'
  return getMany(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

function getExtraPositions ({ token }) {
  const url = host + api + 'mysql/t_extra_posities'
  return getMany(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

function getPositions ({ token }) {
  const url = host + api + 'mysql/t_posities'
  return getMany(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

function getWorkProgress ({ seqwerk, token }) {
  const url = host + api + 'getWorkProgress'
  return ky.get(url, {
    searchParams: {
      seqwerk
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function login ({ username, password }) {
  const url = host + api + 'auth'
  return ky.post(url, {
    json: {
      username,
      password
    }
  }).text()
}

function validate (token) {
  const url = host + api + 'auth/validate'
  return ky.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function addWorkProgress ({ seqpers, idTWerkDetail, createdBy, amount, token, seqwerk, seqwerkdet }) {
  const url = host + api + 'mysql/werk_detail_uitgevoerd'
  return ky.post(url, {
    json: {
      seqpers,
      id_t_werk_detail: idTWerkDetail,
      seqpers_createdby: createdBy,
      aantal: amount,
      seqwerk,
      seqwerkdet
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function deleteWorkProgress ({ progress, token }) {
  const url = host + api + 'mysql/werk_detail_uitgevoerd/' + progress.pro_seqwerkdetuitg
  return ky.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function createWork ({ work, token }) {
  const url = host + api + 'mysql/t_werk_detail'
  work.change_by_app = 1
  return ky.post(url, {
    json: work,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function updateWork ({ id, update, token }) {
  const url = host + api + 'mysql/t_werk_detail/' + id
  update.change_by_app = 1
  return ky.patch(url, {
    json: update,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function deleteWork ({ id, token }) {
  const url = host + api + 'mysql/t_werk_detail/' + id
  return ky.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function createWorkExtra ({ extra, token }) {
  const url = host + api + 'mysql/t_werk_extra'
  return ky.post(url, {
    json: extra,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function deleteWorkExtra ({ id, token }) {
  const url = host + api + 'mysql/t_werk_extra/' + id
  return ky.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function createWorkOtherCosts ({ workOtherCosts, token }) {
  const url = host + api + 'mysql/werk_andere_kosten'
  return ky.post(url, {
    json: workOtherCosts,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function updateWorkOtherCosts ({ id, update, token }) {
  const url = host + api + 'mysql/werk_andere_kosten/' + id
  return ky.patch(url, {
    json: update,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function deleteWorkOtherCosts ({ id, token }) {
  const url = host + api + 'mysql/werk_andere_kosten/' + id
  return ky.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function createComment ({ comment, token }) {
  const url = host + api + 'mysql/opmerkingen'
  return ky.post(url, {
    json: comment,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function updateComment ({ seqopm, update, token }) {
  const url = host + api + 'mysql/opmerkingen/' + seqopm
  return ky.patch(url, {
    json: update,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function deleteComment ({ seqopm, token }) {
  const url = host + api + 'mysql/opmerkingen/' + seqopm
  return ky.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function uploadFile ({ file, filename, seqwerk, seqopm, seqpers, isSignature, token }) {
  const url = host + api + 'file'
  const formData = new FormData()
  formData.append('seqwerk', seqwerk)
  formData.append('seqpers', seqpers)
  formData.append('file', file, filename)
  if (seqopm) {
    formData.append('seqopm', seqopm)
    formData.append('bij_opmerking', true)
  }
  if (isSignature) {
    formData.append('is_handtekening', true)
  }
  return ky.post(url, {
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function updateFile ({ seqafb, update, token }) {
  const url = host + api + 'mysql/afbeeldingen/' + seqafb
  return ky.patch(url, {
    json: update,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

function getFile ({ seqafb, thumbnail, token }) {
  const url = host + api + 'file/' + seqafb + (thumbnail ? '/thumbnail' : '')
  return ky.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

function deleteFile ({ seqafb, token }) {
  const url = host + api + 'mysql/afbeeldingen/' + seqafb
  return ky.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).json()
}

export {
  login,
  validate,
  getPlanning,
  updatePlanning,
  getUser,
  getExtra,
  getExtraPositions,
  getPositions,
  addWorkProgress,
  getWorkProgress,
  deleteWorkProgress,
  createWork,
  updateWork,
  deleteWork,
  createWorkExtra,
  deleteWorkExtra,
  createWorkOtherCosts,
  updateWorkOtherCosts,
  deleteWorkOtherCosts,
  createComment,
  updateComment,
  deleteComment,
  uploadFile,
  updateFile,
  getFile,
  deleteFile
}
