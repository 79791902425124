import React from 'react'
import './AddJob.css'
import Header from '../../components/Header.js'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import Icon from '../../components/Icon.js'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'

const AddJob = ({ seqplan, t }) => {
  function handleRowClick (name) {
    return e => {
      history.push(`/job/${seqplan}/${name}`)
    }
  }

  return (
    <div>
      <Header size="small">
        <div className="AddJob-header">
          <Text color="dark" size="large" weight="bold">{t('add_item')}</Text>
          <div className="AddJob-header-button"><Button weight="normal" onClick={history.goBack}>{t('cancel')}</Button></div>
        </div>
      </Header>
      <div className="AddJob-row AddJob-drill" onClick={handleRowClick('addJobDrill')}>
        <div className="AddJob-row-icon"><Icon name="drill" width={22} height={22} fill="white" /></div>
        <Text color="white" weight="bold">{t('job_drill')}</Text>
        <div className="AddJob-row-icon"><Icon name="caretRight" width={10} height={10} fill="white" /></div>
      </div>
      <div className="AddJob-row AddJob-saw" onClick={handleRowClick('addJobSaw')}>
        <div className="AddJob-row-icon"><Icon name="saw" width={22} height={22} fill="white" /></div>
        <Text color="white" weight="bold">{t('job_saw')}</Text>
        <div className="AddJob-row-icon"><Icon name="caretRight" width={10} height={10} fill="white" /></div>
      </div>
      <div className="AddJob-row AddJob-other" onClick={handleRowClick('addJobOther')}>
        <div className="AddJob-row-icon"><Icon name="ellipsis" width={22} height={22} fill="white" /></div>
        <Text color="white" weight="bold">{t('other_costs')}</Text>
        <div className="AddJob-row-icon"><Icon name="caretRight" width={10} height={10} fill="white" /></div>
      </div>
    </div>
  )
}

const AddJobWithT = withTranslation()(AddJob)

export default AddJobWithT
