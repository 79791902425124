const initialState = {
  tab: 'info'
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_JOB_TAB': {
      return {
        ...initialState,
        tab: action.tab
      }
    }
    default: return state
  }
}

export default reducer
