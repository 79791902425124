function setAppUpdate (state) {
  return {
    type: 'SET_APP_UPDATE',
    state
  }
}

export {
  setAppUpdate
}
