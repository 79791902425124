function getContacts (planning) {
  const contacts = []
  let x = 0
  while (x++ < 3) {
    if (planning[`cont${x}_id`]) {
      contacts.push({
        id: planning[`cont${x}_id`],
        seqcont: planning[`cont${x}_seqcont`],
        naam: planning[`cont${x}_naam`],
        functie: planning[`cont${x}_functie`],
        telefoon: planning[`cont${x}_telefoon`],
        email: planning[`cont${x}_email`],
        seqkla: planning[`cont${x}_seqkla`]
      })
    }
  }
  return contacts
}

export default getContacts
