import React from 'react'
import PropTypes from 'prop-types'

const icons = {
  menuHamburger: props =>
    <svg width="1em" height="1em" viewBox="0 0 17.719 13.978" {...props}>
      <path d="M0 0h17.719v2.953H0zM0 5.512h17.719v2.953H0zM0 11.025h17.719v2.953H0z" />
    </svg>,
  caretRight: props =>
    <svg width="1em" height="1em" viewBox="0 0 7.282 12.013" {...props}>
      <path d="M4.36 5.999L0 10.544l1.531 1.469 5.751-6.014-5.751-6L0 1.469z" />
    </svg>,
  caretLeft: props =>
    <svg width="1em" height="1em" viewBox="0 0 7.282 12.013" {...props}>
      <path d="M2.922 6.014l4.36-4.545L5.751 0 0 6.014l5.751 6 1.531-1.47z" />
    </svg>,
  caretDown: props =>
    <svg width="1em" height="1em" viewBox="0 0 9.898 6" {...props}>
      <path d="M4.956 3.592L1.211 0 0 1.261 4.955 6 9.9 1.261 8.69 0z" />
    </svg>,
  clock: props =>
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zm0 2.52a.56.56 0 01.56.56v3.6l2.634 1.52a.557.557 0 01-.56.963L6.781 7.518a.423.423 0 01-.061-.035A.555.555 0 016.44 7V3.08A.56.56 0 017 2.52z" />
    </svg>,
  manager: props =>
    <svg width="1em" height="1em" viewBox="0 0 8.52 19" {...props}>
      <path d="M8.501 8.135a1.525 1.525 0 00-1.138-1.452l-1.311-.314L4.914 9.42l-.363-1.743h.05a.3.3 0 00.29-.29v-.388a.3.3 0 00-.29-.29h-.678a.3.3 0 00-.29.29v.387a.3.3 0 00.29.29h.048l-.363 1.743-1.139-3.05-1.307.315A1.478 1.478 0 00.024 8.136l-.023 5.008a1.879 1.879 0 001.259 1.767l.388.121.265 3.072a.936.936 0 00.944.9h2.517a.935.935 0 00.944-.9l.557-3.072.388-.121a1.877 1.877 0 001.259-1.767zM7.312 3.051A3.051 3.051 0 114.261 0a3.051 3.051 0 013.051 3.051" />
    </svg>,
  worker: props =>
    <svg width="1em" height="1em" viewBox="0 0 16.859 9.757" {...props}>
      <path d="M1.038 9.758h14.778a1.042 1.042 0 100-2.084h-.284a7.1 7.1 0 00-3.9-6.338l-.589 3.338a.544.544 0 01-.536.45.533.533 0 01-.1-.009.543.543 0 01-.44-.631l.672-3.813a1.424 1.424 0 00-1.201-.67H7.421a1.424 1.424 0 00-1.209.672l.672 3.813a.545.545 0 01-.442.631.533.533 0 01-.1.009.544.544 0 01-.536-.45L5.217 1.34a7.1 7.1 0 00-3.9 6.337h-.279a1.041 1.041 0 100 2.082z" />
    </svg>,
  i: props =>
    <svg width="1em" height="1em" viewBox="0 0 8.38 19.002" {...props}>
      <path d="M8.125 16.942c-.088.353-.16.665-.245.971a.25.25 0 01-.139.139 26.29 26.29 0 01-2.647.857 3.879 3.879 0 01-2.33-.2 2.425 2.425 0 01-1.504-2.434 17.333 17.333 0 01.785-3.546c.245-.95.511-1.913.722-2.881a3.333 3.333 0 00-.011-1.024.7.7 0 00-.637-.637 3.147 3.147 0 00-1.788.171c-.089.034-.19.05-.327.088.082-.344.145-.648.232-.95a.322.322 0 01.179-.178 12.188 12.188 0 012.894-.883 4.158 4.158 0 011.888.19A2.41 2.41 0 016.82 9.184a21.08 21.08 0 01-.868 3.776q-.338 1.368-.655 2.742a1.917 1.917 0 00-.03.483c.015.7.3 1.028.988 1.041a9.372 9.372 0 001.433-.154 3.279 3.279 0 00.445-.129zM6.048.002a2.268 2.268 0 012.324 2.09 2.166 2.166 0 01-1.309 2.1 2.473 2.473 0 01-3.23-1.079A2.244 2.244 0 016.048.002z" />
    </svg>,
  bill: props =>
    <svg width="1em" height="1em" viewBox="0 0 18 17.679" {...props}>
      <path d="M6.933 7.279h3.983a.861.861 0 000-1.722H6.933a.861.861 0 100 1.722zM13.931 8.572h-7a.861.861 0 100 1.722h7a.861.861 0 100-1.722z" />
      <path d="M17.117.734a1.486 1.486 0 00-1.616.237l-.581.516-1.227-1.1a1.509 1.509 0 00-2.024 0l-1.227 1.1-1.227-1.1a1.508 1.508 0 00-2.024 0l-1.227 1.1-.581-.516a1.507 1.507 0 00-2.5 1.12v9.86H.862a.862.862 0 00-.861.86v1.572a3.294 3.294 0 003.187 3.294h11.513a3.294 3.294 0 003.3-3.292V2.111a1.487 1.487 0 00-.883-1.378zM3.295 15.978a1.572 1.572 0 01-1.572-1.567v-.733h9.689v.711a3.28 3.28 0 00.389 1.589zm12.983-1.567a1.583 1.583 0 01-3.165 0v-1.6a.861.861 0 00-.861-.861H4.586V2.586l.366.323a1.506 1.506 0 002 0l1.227-1.1 1.249 1.1a1.506 1.506 0 002.024 0l1.227-1.1 1.227 1.1a1.506 1.506 0 002 0l.366-.323z" />
    </svg>,
  pin: props =>
    <svg width="1em" height="1em" viewBox="0 0 12.526 16" {...props}>
      <path d="M11.817 3.447A6.252 6.252 0 003.424.667a6.336 6.336 0 00-2.78 8.392 64.934 64.934 0 004.954 6.639.81.81 0 001.264 0 73.9 73.9 0 004.971-6.641 5.961 5.961 0 00-.016-5.611zM6.239 9.53a3.252 3.252 0 113.252-3.252A3.254 3.254 0 016.239 9.53z" />
    </svg>,
  navigation: props =>
    <svg width="1em" height="1em" viewBox="0 0 14.374 14.374" {...props}>
      <path d="M8.626 13.999l5.7-13.127a.623.623 0 00-.82-.82L.376 5.748a.623.623 0 00.248 1.2h6.183a.624.624 0 01.623.623v6.183a.623.623 0 001.2.248z" />
    </svg>,
  car: props =>
    <svg width="1em" height="1em" viewBox="0 0 14 13.006" {...props}>
      <path d="M2.571 0A1.65 1.65 0 001.6 1.059C1.058 2.614.561 4.149 0 5.682l.022 5.4v.843a1.083 1.083 0 001.08 1.08 1.07 1.07 0 001.08-1.08v-.821h9.636v.821a1.07 1.07 0 001.08 1.08 1.083 1.083 0 001.08-1.08v-.843l.022-5.4a170.155 170.155 0 01-1.6-4.623A1.65 1.65 0 0011.428 0zM1.555 4.839l1.275-3.63h8.318l1.3 3.63zm9.766 1.51a1.231 1.231 0 00-1.232 1.232 1.245 1.245 0 001.232 1.232 1.231 1.231 0 001.232-1.232 1.218 1.218 0 00-1.232-1.232zm-8.664 0a1.218 1.218 0 011.232 1.232 1.231 1.231 0 01-1.232 1.232 1.245 1.245 0 01-1.232-1.232 1.231 1.231 0 011.232-1.232z" fill="#404040" fillRule="evenodd" />
    </svg>,
  pencil: props =>
    <svg width="1em" height="1em" viewBox="0 0 13.121 13.127" {...props}>
      <path d="M9.019 0a.82.82 0 00-.58.24L1.47 7.214a.811.811 0 00-.205.365L.034 12.092A.82.82 0 001.036 13.1l4.513-1.231a.817.817 0 00.365-.205l6.974-6.974a.821.821 0 00.234-.587 4.1 4.1 0 00-4.1-4.1zM3.134 10.824a1.639 1.639 0 00-.836-.836l.48-1.758a2.462 2.462 0 012.117 2.115z" />
    </svg>,
  write: props =>
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <path d="M11.18 0a2.814 2.814 0 00-2 .825l-.3.3 3.992 3.992.3-.3a2.821 2.821 0 00-2-4.817zM8.123 1.885L0 10.008V14h3.993l8.123-8.123zM7 12.925v1.077h7v-1.077z" />
    </svg>,
  signature: props =>
    <svg width="1em" height="1em" viewBox="0 0 13.209 7.172" {...props}>
      <path d="M.875.002a.9.9 0 00-.261 1.743c.372.135.271.087.289.242a4.084 4.084 0 01-.2 1.063 4.18 4.18 0 00-.242 1.734 2.227 2.227 0 001.49 1.705 5.336 5.336 0 003.524-.41 6.812 6.812 0 012.172-.447 1.222 1.222 0 00.037.587 1.491 1.491 0 00.988.886 4.151 4.151 0 002.153-.214 13.6 13.6 0 001.808-.708.895.895 0 10-.764-1.613 12.24 12.24 0 01-1.557.606 3.218 3.218 0 01-.9.14 1.168 1.168 0 00-.028-.494 1.5 1.5 0 00-.932-.932 5.91 5.91 0 00-3.542.494 4.952 4.952 0 01-2.377.41c-.383-.132-.271-.089-.289-.233a3.9 3.9 0 01.186-1.035 4.334 4.334 0 00.245-1.735A2.231 2.231 0 001.221.057.894.894 0 00.867.001z" />
    </svg>,
  phone: props =>
    <svg width="1em" height="1em" viewBox="0 0 14 14.571" {...props}>
      <path d="M3.714.342l1.748 3.2a.813.813 0 01-.243.971l-1.554.971a10.2 10.2 0 001.991 3.059 9.517 9.517 0 002.914 2.185l1.067-1.451a.7.7 0 01.971-.195l3.108 1.991a.584.584 0 01.146.874l-1.505 2.137c-1.942 1.505-5.729-.68-8.643-3.787C.899 7.189-1.043 3.307.608 1.459L2.793.099a.652.652 0 01.923.243z" />
    </svg>,
  comment: props =>
    <svg width="1em" height="1em" viewBox="0 0 16 13.418" {...props}>
      <path d="M0 2.664A2.661 2.661 0 012.66 0h10.68A2.662 2.662 0 0116 2.664v9.7c0 1.108-.64 1.385-1.435.614l-2.388-2.315h-9.5A2.668 2.668 0 010 8.002z" />
    </svg>,
  camera: props =>
    <svg width="1em" height="1em" viewBox="0 0 19.665 14.202" {...props}>
      <path d="M11.472 8.194a1.639 1.639 0 11-1.639-1.639 1.638 1.638 0 011.639 1.639" />
      <path d="M18.026 2.185h-4.37L12.563 0H7.1L6.007 2.185H1.639A1.609 1.609 0 000 3.823v8.74A1.643 1.643 0 001.639 14.2h16.387a1.643 1.643 0 001.639-1.639v-8.74a1.61 1.61 0 00-1.639-1.636zm-8.194 9.833a3.823 3.823 0 113.823-3.823 3.8 3.8 0 01-3.822 3.822z" />
    </svg>,
  gallery: props =>
    <svg width="1em" height="1em" viewBox="0 0 20.692 15.409" {...props}>
      <path d="M19.901 0H.801a.766.766 0 00-.794.734v13.941a.766.766 0 00.794.734h19.1a.766.766 0 00.794-.734V.734A.766.766 0 0019.901 0zm-.8 6.652l-6.774 3.669-5-4.036a.847.847 0 00-.98-.049l-4.763 3.2V1.467h17.517z" />
      <path d="M12.256 7.252a2.384 2.384 0 002.2-1.4 2.186 2.186 0 00-.516-2.47 2.467 2.467 0 00-2.594-.491 2.267 2.267 0 00-1.472 2.094 2.325 2.325 0 002.38 2.267z" />
    </svg>,
  check: props =>
    <svg width="1em" height="1em" viewBox="0 0 16.111 13.33" {...props}>
      <path d="M13.873 0l-1.055 1.119-7.352 7.8-3.293-3.293L0 7.8l5.53 5.53 1.088-1.151L16.111 2.11z" />
    </svg>,
  delete: props =>
    <svg width="1em" height="1em" viewBox="0 0 12 13.307" {...props}>
      <path d="M11.999 2.496a.573.573 0 00-.56-.563H9.306v-.236-.282A1.413 1.413 0 007.906 0h-3.78a1.513 1.513 0 00-.645.148 1.286 1.286 0 00-.389.28 1.516 1.516 0 00-.313.505 1.492 1.492 0 00-.085.566v.434H.561a.56.56 0 000 1.121h.607v8.209a5.684 5.684 0 00.006.6 1.6 1.6 0 00.779 1.23 1.65 1.65 0 00.832.213h6.44a1.619 1.619 0 00.857-.233 1.6 1.6 0 00.749-1.266V3.053h.607a.568.568 0 00.561-.56zM3.812 1.35l-.01.013a.08.08 0 010-.012c0-.006 0-.011.007-.017a.313.313 0 01.006-.038v.035l.028-.067-.018.022.02-.025c0-.011.01-.021.014-.032a.237.237 0 00-.011.029l.044-.056-.025.018.027-.021.021-.027-.018.025.056-.044-.029.011.032-.014.025-.02-.022.018c.022-.01.045-.018.066-.028H3.99l.041.003.029-.012-.027.011.08-.011-.035.006h3.383c.15 0 .307-.014.458 0h.012l-.034-.006h-.006c-.01 0-.018-.006-.028-.007a.919.919 0 00.1.012.033.033 0 01.012 0l.038.006h-.035l.067.028-.022-.018.025.02.032.014-.029-.011.056.044c-.006-.008-.011-.017-.018-.025l.021.027.027.021-.025-.018.044.056c0-.01-.007-.02-.011-.029a.239.239 0 00.014.032l.02.025-.019-.025.028.067v-.035a.313.313 0 00.006.038l.007.03c0-.008-.007-.017-.011-.025 0 .036.01.073.014.108a.556.556 0 00-.014-.066c.012.176 0 .357 0 .532v.017H3.815v-.016c0-.175-.014-.356 0-.532a.626.626 0 00-.012.066V1.42c0-.025.007-.049.01-.073v.006zm.1 9.208a.422.422 0 01-.844 0v-.01-5.653a.42.42 0 01.84 0v5.668zm2.507-4.967v4.972a.42.42 0 01-.84 0v-.01-5.658a.42.42 0 01.84 0V5.591zm2.512-.686v5.653a.42.42 0 11-.84 0V4.895a.43.43 0 01.423-.42.359.359 0 01.18.04l.012.006a.063.063 0 01.018.011l.007.007h.012c.008.014.016.014.024.021a.438.438 0 01.161.276v.066z" />
    </svg>,
  box: props =>
    <svg width="1em" height="1em" viewBox="0 0 16 16.424" {...props}>
      <path d="M7.954.006a.4.4 0 00-.118.033L.257 3.197A.421.421 0 000 3.585v9.263a.423.423 0 00.257.388l7.579 3.158a.423.423 0 00.329 0l7.579-3.158a.423.423 0 00.256-.392V3.581a.421.421 0 00-.257-.388L8.164.035a.421.421 0 00-.21-.029zM8 .881l6.48 2.7L8 6.285l-6.48-2.7zM.842 4.217l6.737 2.809v8.349L.842 12.566zm14.316 0v8.349l-6.737 2.809V7.022z" />
    </svg>,
  diameter: props =>
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 14.667A6.667 6.667 0 1114.667 8 6.674 6.674 0 018 14.667z" />
      <path d="M10.829 10.483L5.235 4.889h1.149V4H3.718v2.667h.889v-1.15l5.594 5.594h-1.15V12h2.667V9.333h-.889z" />
    </svg>,
  depth: props =>
    <svg width="1em" height="1em" viewBox="0 0 11.347 15.5" {...props}>
      <path d="M6.19 10.9V2h-.999v8.9l-.914-.915-.707.707 2.121 2.122 2.121-2.122-.707-.707z" />
      <path d="M.5 0v15h10.046V0" fill="none" stroke={props.fill || '#404040'} />
    </svg>,
  poundSign: props =>
    <svg width="1em" height="1em" viewBox="0 0 16.908 16.908" {...props}>
      <path d="M16.308 6.039h-14.5a.604.604 0 110-1.208h14.5a.604.604 0 110 1.208zM15.096 12.077H.604a.604.604 0 010-1.208h14.492a.604.604 0 010 1.208z" />
      <path d="M3.019 16.907a.625.625 0 01-.217-.04.6.6 0 01-.346-.78L8.494.387a.604.604 0 111.128.434l-6.038 15.7a.6.6 0 01-.564.386zM8.454 16.908a.625.625 0 01-.217-.04.6.6 0 01-.346-.78l6.038-15.7a.604.604 0 111.127.434l-6.038 15.7a.6.6 0 01-.563.386z" />
    </svg>,
  drill: props =>
    <svg width="1em" height="1em" viewBox="0 0 16.337 19.968" {...props}>
      <g transform="translate(-22 -146)">
        <rect width={7.014} height={10.52} rx={0.5} transform="translate(26.662 146)" />
        <path d="M37.899 148.63h-3.068v2.63h3.068a.438.438 0 00.438-.438v-1.754a.438.438 0 00-.438-.438zM28.416 160.27a1.315 1.315 0 001.315 1.315v3.945a.439.439 0 00.877 0v-3.945a1.315 1.315 0 001.315-1.315v-2.192h-3.507zM22 149.068v1.754a.438.438 0 00.438.438h3.069v-2.63h-3.069a.438.438 0 00-.438.438z" />
      </g>
    </svg>,
  add: props =>
    <svg width="1em" height="1em" viewBox="0 0 13.829 13.83" {...props}>
      <path d="M5.851 0v5.851H0v2.128h5.851v5.851h2.128V7.979h5.851V5.851H7.979V0z" />
    </svg>,
  ellipsis: props =>
    <svg width="1em" height="1em" viewBox="0 0 23.563 6.08" {...props}>
      <path d="M20.523 0a3.04 3.04 0 11-3.04 3.04A3.04 3.04 0 0120.523 0zM3.04 0A3.04 3.04 0 110 3.04 3.04 3.04 0 013.04 0zm8.742 0a3.04 3.04 0 11-3.04 3.04A3.04 3.04 0 0111.782 0z" />
    </svg>,
  search: props =>
    <svg width="1em" height="1em" viewBox="0 0 17.055 16.858" {...props}>
      <path d="M16.601 13.843l-3.727-3.738a6.742 6.742 0 00.814-3.259 6.845 6.845 0 10-6.846 6.846 6.77 6.77 0 003.51-.978l3.683 3.683a1.511 1.511 0 002.174 0l.435-.435a1.522 1.522 0 00-.043-2.119zm-9.78-2.445a4.435 4.435 0 11.021 0z" />
    </svg>,
  duplicate: props =>
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path d="M7.182 1.455h7.364v8.727h-1.455v1.455h1.455A1.456 1.456 0 0016 10.182V1.455A1.456 1.456 0 0014.545 0H5.818a1.456 1.456 0 00-1.455 1.455v1.454h1.455V1.455zM0 5.82a1.455 1.455 0 011.456-1.456h8.724a1.455 1.455 0 011.456 1.456v8.724A1.455 1.455 0 0110.18 16H1.456A1.455 1.455 0 010 14.544z" />
    </svg>,
  cross: props =>
    <svg width="1em" height="1em" viewBox="0 0 23.049 23.049" {...props}>
      <path d="M16.404 4.88l-4.879 4.878L6.646 4.88 4.878 6.647l4.88 4.88-4.88 4.878 1.768 1.768 4.879-4.879 4.879 4.879 1.768-1.768-4.88-4.879 4.88-4.879z" />
    </svg>,
  circle: props =>
    <svg width="100%" height="100%" viewBox="-1 -1 103 103" {...props}>
      <circle cx="51" cy="51" r="50"/>
    </svg>,
  saw: props =>
    <svg width="1em" height="1em" viewBox="0 0 18.035 16.44" {...props}>
      <path d="M7.125 5.546l-7 7.008a.392.392 0 000 .573l3.155 3.19a.421.421 0 00.3.123h.041a.418.418 0 00.3-.181l1.737-2.607.746 1.5a.426.426 0 00.729 0l1.762-3.516.689 1.386a.41.41 0 00.344.229.384.384 0 00.369-.18l1.705-2.566-4.615-4.639a1.921 1.921 0 01-.254-.32zM17.69 5.119L12.912.34a1.158 1.158 0 00-1.64 0L7.993 3.619a1.179 1.179 0 000 1.639l4.779 4.779a1.2 1.2 0 00.82.336 1.17 1.17 0 00.82-.336l3.279-3.279a1.146 1.146 0 000-1.639zm-4.246 2.7a.424.424 0 01-.287.115.41.41 0 01-.287-.115l-2.655-2.646a.411.411 0 010-.582.393.393 0 01.574 0l2.656 2.656a.409.409 0 010 .582z" />
    </svg>,
  length: props =>
    <svg width="1em" height="1em" viewBox="0 0 17 12.728" {...props}>
      <path d="M3.95 6.864h8.9l-.914.914.707.707 2.121-2.121-2.121-2.121-.707.707.914.914h-8.9l.915-.914-.708-.707-2.12 2.121 2.12 2.121.708-.707z" />
      <path stroke={props.fill || '#000'} d="M16.5 1.5v9.8M.5 1.5v9.8" />
    </svg>,
  sawDiameter: props =>
    <svg width="1em" height="1em" viewBox="0 0 11.046 15.5" {...props}>
      <path d="M.5 0v15h10.046V0" fill="none" stroke={props.fill || '#404040'} />
      <path d="M3.088 7.99h4.95l-.823.822.637.637 1.902-1.903L7.847 5.64l-.636.636.82.82H3.08l.824-.824-.636-.637-1.902 1.902 1.907 1.907.636-.636z" />
    </svg>
}

const Icon = ({ name, ...props }) => {
  const ThisIcon = icons[name]
  return <ThisIcon {...props} />
}

Icon.propTypes = {
  name: PropTypes.string
}

export default Icon
