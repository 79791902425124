const initialState = {
  loading: false,
  planning: null,
  error: null
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_PLANNING_LOADING': {
      return {
        ...initialState,
        loading: true
      }
    }
    case 'SET_PLANNING': {
      return {
        ...initialState,
        planning: action.planning
      }
    }
    case 'SET_PLANNING_ERROR': {
      return {
        ...initialState,
        error: action.error
      }
    }
    default: return state
  }
}

export default reducer
