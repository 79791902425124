import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon.js'
import './Checkbox.css'

class Checkbox extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.checked || false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleInputClick = this.handleInputClick.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.checked !== this.props.checked) {
      this.setState(state => ({ value: this.props.checked }))
    }
  }

  handleChange (e) {
    const newValue = !this.state.value
    this.setState(state => ({
      value: newValue
    }))
    this.props.onChange(newValue)
  }

  handleInputClick (e) {
    const newValue = e.target.checked
    this.setState(state => ({
      value: newValue
    }))
    this.props.onChange(newValue)
  }

  render () {
    const { value } = this.state
    const { id, color } = this.props
    const iconColor = color || 'purple'
    return (
      <>
        <input className="Checkbox-input" type="checkbox" checked={value} onChange={this.handleInputClick} id={id} />
        <div className="Checkbox" onClick={this.handleChange}>
          { value && <Icon name="check" fill={`var(--${iconColor})`} /> }
        </div>
      </>
    )
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  color: PropTypes.string
}

export default Checkbox
