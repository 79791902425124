const initialState = {
  loading: false,
  extraPositions: null,
  error: null
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_EXTRA_POSITIONS_LOADING': {
      return {
        ...initialState,
        loading: true
      }
    }
    case 'SET_EXTRA_POSITIONS': {
      return {
        ...initialState,
        extraPositions: action.extraPositions
      }
    }
    case 'SET_EXTRA_POSITIONS_ERROR': {
      return {
        ...initialState,
        error: action.error
      }
    }
    default: return state
  }
}

export default reducer
