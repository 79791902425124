import React, { Component } from 'react'
import './Messages.css'
import Text from './Text.js'

class Messages extends Component {
  constructor (props) {
    super(props)

    this.state = {
      messages: []
    }

    this.handleNewMessage = this.handleNewMessage.bind(this)
    this.removeOldMessages = this.removeOldMessages.bind(this)

    this.currentId = 0
    this.interval = null
    this.isUnmounting = false
  }

  componentDidMount () {
    window.addEventListener('addMessage', this.handleNewMessage)
  }

  componentWillUnmount () {
    this.isUnmounting = true
    window.removeEventListener('addMessage', this.handleNewMessage)
    clearInterval(this.interval)
  }

  getId () {
    return this.currentId++
  }

  handleNewMessage (e) {
    const newMessage = {
      ...e.detail,
      id: this.getId(),
      start: new Date().getTime()
    }
    this.startInterval()
    this.setState(state => ({ messages: [...state.messages, newMessage] }))
  }

  startInterval () {
    if (this.interval) return
    this.interval = setInterval(this.removeOldMessages, 100)
  }

  removeOldMessages () {
    if (this.isUnmounting) return
    const messages = this.state.messages
    const now = new Date().getTime()
    const newMessages = []
    // eslint-disable-next-line
    for (const message of messages) {
      if (message.start + 3000 >= now) {
        newMessages.push(message)
      }
    }
    if (newMessages.lenth !== messages.length) {
      this.setState(state => ({ messages: newMessages }))
    }
    if (!newMessages.length) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  render () {
    const messages = this.state.messages
    return (
      <div className="Messages">
        {
          messages.map(message => {
            if (message.type === 'error') {
              return <div key={message.id} className="Message-row Message-type-error"><Text color="white">{message.message.toString()}</Text></div>
            } else {
              return <div key={message.id} className="Message-row"><Text color="white">{message.message}</Text></div>
            }
          })
        }
      </div>
    )
  }
}

function addMessage ({ type, message }) {
  const customEvent = new window.CustomEvent('addMessage', {
    detail: {
      type,
      message
    }
  })
  window.dispatchEvent(customEvent)
}

export default Messages

export {
  addMessage
}
