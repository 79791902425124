import React, { Component, useState } from 'react'
import './Bill.css'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import Icon from '../../components/Icon.js'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'
import getWorkProgress from '../../connector/getWorkProgress.js'
import { deleteWorkProgress, deleteWork, deleteWorkExtra, deleteWorkOtherCosts, updateWorkOtherCosts } from '../../connector/connector.js'
import { getToken } from '../../token.js'
import reloadPlanning from '../../reloadPlanning.js'
import { addMessage } from '../../components/Messages.js'
import i18n from '../../i18n.js'
import AddPicturesToPlanning from '../../components/AddPicturesToPlanning/AddPicturesToPlanning.js'
import JobOtherEditModal from '../../components/JobOtherEditModal/JobOtherEditModal.js'
import { isAfter, startOfDay } from 'date-fns'
import convertTimezone from '../../convertTimezone.js'

class Bill extends Component {
  constructor (props) {
    super(props)

    let workProgress = []
    if (props.workProgress) {
      workProgress = props.workProgress.progress || []
    }

    this.state = {
      workProgress
    }

    this.handleDeleteDoneClick = this.handleDeleteDoneClick.bind(this)
    this.handleEndClick = this.handleEndClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.workProgress || !prevProps.workProgress) return
    if (!this.props.workProgress.loading && prevProps.workProgress.loading) {
      this.setState(state => ({ workProgress: this.props.workProgress.progress }))
    }
  }

  componentDidMount () {
    this.getMyWorkProgress() // always update workProgress redux state when loading this view
  }

  getMyWorkProgress () {
    const token = getToken()
    const seqwerk = this.props.planning.w_seqwerk
    getWorkProgress({ seqwerk, token })
  }

  handleEndClick (e) {
    history.push(`/job/${this.props.planning.p_seqplan}/endJob`)
  }

  handleAddClick (e) {
    history.push(`/job/${this.props.planning.p_seqplan}/addJob`)
  }

  handleItemClick (detail) {
    if (this.props.planning && this.props.planning.p_uitgevoerd) return
    return e => {
      const planning = this.props.planning
      if (!planning) return
      const jobId = planning.p_seqplan
      const detailId = detail.det_id
      history.push(`/job/${jobId}/editJob/${detailId}`)
    }
  }

  handleSawItemClick (detail) {
    if (this.props.planning && this.props.planning.p_uitgevoerd) return
    return e => {
      const planning = this.props.planning
      if (!planning) return
      const jobId = planning.p_seqplan
      const detailId = detail.det_id
      history.push(`/job/${jobId}/editJobSaw/${detailId}`)
    }
  }

  handleAddWorkClick (detail) {
    return e => {
      const planning = this.props.planning
      if (!planning) return
      const jobId = planning.p_seqplan
      const detailId = detail.det_id
      history.push(`/job/${jobId}/addJobProgress/${detailId}`)
    }
  }

  handleDeleteWorkClick (detail) {
    return async e => {
      const confirm = window.confirm(this.props.t('confirm_delete'))
      if (!confirm) return
      try {
        const token = getToken()
        const workPromise = deleteWork({
          id: detail.det_id,
          token
        })
        const extraPromises = (detail.extra || []).map(extra => {
          return deleteWorkExtra({
            id: extra.id,
            token
          })
        })
        await Promise.all([
          workPromise,
          ...extraPromises
        ])
        await reloadPlanning()
      } catch (error) {
        console.error(error)
        addMessage({
          type: 'error',
          message: error
        })
      }
      addMessage({
        type: 'info',
        message: this.props.t('job_deleted')
      })
    }
  }

  handleDeleteDoneClick (progress) {
    return async e => {
      const confirm = window.confirm(this.props.t('confirm_delete'))
      if (!confirm) return
      try {
        await deleteWorkProgress({ progress, token: getToken() })
      } catch (error) {
        console.error(error)
        addMessage({
          type: 'error',
          message: error
        })
      }
      addMessage({
        type: 'info',
        message: this.props.t('progress_deleted')
      })
      this.getMyWorkProgress()
    }
  }

  handleJobOtherDelete (item) {
    return async e => {
      const confirm = window.confirm(this.props.t('confirm_delete'))
      if (!confirm) return
      await deleteWorkOtherCosts({ id: item.oc_seqwerkakost, token: getToken() })
      await reloadPlanning()
    }
  }

  handleJobOtherUpdate (item) {
    return async ({ comment, amount }) => {
      await updateWorkOtherCosts({ id: item.oc_seqwerkakost, update: { opmerking: comment, aantal: amount }, token: getToken() })
      await reloadPlanning()
    }
  }

  render () {
    const {
      planning,
      t,
      user
    } = this.props
    const {
      workProgress
    } = this.state
    const werkDetail = planning.werk_detail || []
    const drillDetail = werkDetail.filter(work => (work.det_typewerk || 'B').toUpperCase() === 'B')
    const sawDetail = werkDetail.filter(work => (work.det_typewerk || '').toUpperCase() === 'Z')
    const mySeqpers = (user.user || {}).seqpers
    const isFinished = planning.p_uitgevoerd
    const other = planning.otherCosts || []
    const lang = i18n.language
    const planningVanDate = convertTimezone(new Date(planning.p_van))
    const isFuture = isAfter(startOfDay(planningVanDate), startOfDay(new Date()))

    const showDrillJobs = !!drillDetail.length
    const showSawJobs = !!sawDetail.length

    return (
      <>
        <div className={`Bill-container ${!showDrillJobs ? 'Bill-container-hidden' : ''}`}>
          <div className="Bill-header">
            <div className="Bill-header-icon"><Icon name="drill" width={20} height={20} fill="white" /></div>
            <Text color="white" weight="bold">{t('job_drill')}</Text>
          </div>
          <div className="Bill-table-header">
            <div className="Bill-table-head Bill-table-cell-small"><Icon name="poundSign" width={16} height={16} /></div>
            <div className="Bill-table-head Bill-table-cell-wide"><Icon name="box" width={16} height={16} /></div>
            <div className="Bill-table-head Bill-table-cell-wide"><Icon name="diameter" width={16} height={16} /></div>
            <div className="Bill-table-head Bill-table-cell-wide"><Icon name="depth" width={16} height={16} /></div>
            <div className="Bill-table-cell-fill"></div>
          </div>
          {
            drillDetail.map(detail => {
              const id = detail.det_id
              const done = workProgress.filter(progress => progress.pro_id_t_werk_detail === id)
              return (
                <div key={`drill-${detail.det_id}`}>
                  <div className="Bill-table-row">
                    <div className="Bill-table-row-top">
                      <div className="Bill-table-cell-small" onClick={isFuture ? () => {} : this.handleItemClick(detail)}>
                        <Text color="white" weight="bold">{done.reduce((accumulator, currentValue) => accumulator + currentValue.pro_aantal, 0)}</Text>
                        <Text color="white">/{detail.det_aantal || '?'}</Text>
                      </div>
                      <div className="Bill-table-row-info" onClick={isFuture ? () => {} : this.handleItemClick(detail)}>
                        <div className="Bill-table-row-info-values">
                          <div className="Bill-table-cell-wide">
                            <Text color="purple" weight="bold">{detail.pos_positie_code || '?'}</Text>
                          </div>
                          <div className="Bill-table-cell-wide Bill-table-cell-column">
                            <Text color="purple" weight="bold">{detail.det_diameter || '?'}</Text>
                            <Text color="purple" size="tiny">mm</Text>
                          </div>
                          <div className="Bill-table-cell-wide Bill-table-cell-column">
                            <Text color="purple" weight="bold">{detail.det_diepte || '?'}</Text>
                            <Text color="purple" size="tiny">cm</Text>
                          </div>
                        </div>
                      </div>
                      <div className="Bill-table-row-buttons">
                        { !isFinished && !isFuture &&
                          <>
                            <Button onClick={this.handleAddWorkClick(detail)}><Icon name="add" fill="var(--purple)" /></Button>
                            <Button onClick={this.handleDeleteWorkClick(detail)}><Icon name="delete" fill="var(--purple)" /></Button>
                          </>
                        }
                      </div>
                    </div>
                    <div className="Bill-table-row-info-extra" onClick={isFuture ? () => {} : this.handleItemClick(detail)}>
                      { !!done.length &&
                        <div className="Bill-table-done-amount-initial"></div>
                      }
                      {(detail.extra || []).map(extra =>
                        <div key={`extra-${detail.det_id}-${extra.id}`} className="Bill-table-row-info-extra-row"><Text size="extra-tiny" color="purple-alt">+ {extra.omschrijving_toeslag}</Text></div>
                      )}
                    </div>
                  </div>
                  {
                    done.map((progress, index) => {
                      const workerName = `${progress.pers_voornaam || ''} ${progress.pers_achternaam || ''}`
                      const createdbyName = `${progress.perscreate_voornaam || ''} ${progress.perscreate_achternaam || ''}`
                      const createdbyIsMe = progress.perscreate_seqpers === mySeqpers
                      return (
                        <div key={`done-${detail.det_id}-${progress.pro_seqwerkdetuitg}`} className="Bill-table-done-row">
                          <div className="Bill-table-done-amount">
                            <div className="Bill-table-done-amount-arrow">
                              <div className="Bill-table-done-amount-line"></div>
                              <div className="Bill-table-done-amount-point">
                                <svg width={10} height={10} viewBox="0 0 10 10"><polygon points="0,0 5,5 0,10" style={{ fill: 'var(--purple)' }} /></svg>
                              </div>
                              { index + 1 < done.length && <div className="Bill-table-done-amount-extend"></div> }
                            </div>
                            <div className="Bill-table-done-amount-number"><Text weight="bold" color="purple">{progress.pro_aantal}</Text></div>
                          </div>
                          <div className="Bill-table-done-worker">
                            <Text color="dark">{workerName}</Text>
                            <div><Text color="light" size="small">Ingevoerd door: {createdbyName}</Text></div>
                          </div>
                          <div className="Bill-table-done-buttons Bill-table-row-buttons">
                            <div className="Bill-table-done-fade"></div>
                            { !isFinished && createdbyIsMe && <Button onClick={this.handleDeleteDoneClick(progress)}><Icon name="delete" fill="var(--purple)" /></Button> }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
        <div className={`Bill-container Bill-container-saw ${!showSawJobs ? 'Bill-container-hidden' : ''}`}>
          <div className="Bill-header">
            <div className="Bill-header-icon"><Icon name="saw" width={20} height={20} fill="white" /></div>
            <Text color="white" weight="bold">{t('job_saw')}</Text>
          </div>
          <div className="Bill-table-header">
            <div className="Bill-table-head Bill-table-cell-small"><Icon name="poundSign" width={16} height={16} /></div>
            <div className="Bill-table-head Bill-table-cell-wide"><Icon name="box" width={16} height={16} /></div>
            <div className="Bill-table-head Bill-table-cell-wide"><Icon name="length" width={16} height={16} /></div>
            <div className="Bill-table-head Bill-table-cell-wide"><Icon name="depth" width={16} height={16} /></div>
            <div className="Bill-table-cell-fill"></div>
          </div>
          {
            sawDetail.map(detail => {
              const id = detail.det_id
              const done = workProgress.filter(progress => progress.pro_id_t_werk_detail === id)
              return (
                <div key={`drill-${detail.det_id}`}>
                  <div className="Bill-table-row">
                    <div className="Bill-table-row-top">
                      <div className="Bill-table-cell-small" onClick={isFuture ? () => {} : this.handleSawItemClick(detail)}>
                        <Text color="white" weight="bold">{done.reduce((accumulator, currentValue) => accumulator + currentValue.pro_aantal, 0)}</Text>
                        <Text color="white">/{detail.det_aantal || '?'}</Text>
                      </div>
                      <div className="Bill-table-row-info" onClick={isFuture ? () => {} : this.handleSawItemClick(detail)}>
                        <div className="Bill-table-row-info-values">
                          <div className="Bill-table-cell-wide">
                            <Text color="blue" weight="bold">{detail.pos_positie_code || '?'}</Text>
                          </div>
                          <div className="Bill-table-cell-wide Bill-table-cell-column">
                            <Text color="blue" weight="bold">{detail.det_lengte || '?'}</Text>
                            <Text color="blue" size="tiny">m</Text>
                          </div>
                          <div className="Bill-table-cell-wide Bill-table-cell-column">
                            <Text color="blue" weight="bold">{detail.det_diepte || '?'}</Text>
                            <Text color="blue" size="tiny">cm</Text>
                          </div>
                        </div>
                      </div>
                      <div className="Bill-table-row-buttons">
                        { !isFinished && !isFuture &&
                          <>
                            <Button onClick={this.handleAddWorkClick(detail)}><Icon name="add" fill="var(--blue)" /></Button>
                            <Button onClick={this.handleDeleteWorkClick(detail)}><Icon name="delete" fill="var(--blue)" /></Button>
                          </>
                        }
                      </div>
                    </div>
                    <div className="Bill-table-row-info-extra" onClick={isFuture ? () => {} : this.handleSawItemClick(detail)}>
                      { !!done.length &&
                        <div className="Bill-table-done-amount-initial"></div>
                      }
                      {(detail.extra || []).map(extra =>
                        <div key={`extra-${detail.det_id}-${extra.id}`} className="Bill-table-row-info-extra-row"><Text size="extra-tiny" color="blue">+ {extra.omschrijving_toeslag}</Text></div>
                      )}
                    </div>
                  </div>
                  {
                    done.map((progress, index) => {
                      const workerName = `${progress.pers_voornaam || ''} ${progress.pers_achternaam || ''}`
                      const createdbyName = `${progress.perscreate_voornaam || ''} ${progress.perscreate_achternaam || ''}`
                      const createdbyIsMe = progress.perscreate_seqpers === mySeqpers
                      return (
                        <div key={`done-${detail.det_id}-${progress.pro_seqwerkdetuitg}`} className="Bill-table-done-row">
                          <div className="Bill-table-done-amount">
                            <div className="Bill-table-done-amount-arrow">
                              <div className="Bill-table-done-amount-line"></div>
                              <div className="Bill-table-done-amount-point">
                                <svg width={10} height={10} viewBox="0 0 10 10"><polygon points="0,0 5,5 0,10" style={{ fill: 'var(--blue)' }} /></svg>
                              </div>
                              { index + 1 < done.length && <div className="Bill-table-done-amount-extend"></div> }
                            </div>
                            <div className="Bill-table-done-amount-number"><Text weight="bold" color="blue">{progress.pro_aantal}</Text></div>
                          </div>
                          <div className="Bill-table-done-worker">
                            <Text color="dark">{workerName}</Text>
                            <div><Text color="light" size="small">Ingevoerd door: {createdbyName}</Text></div>
                          </div>
                          <div className="Bill-table-done-buttons Bill-table-row-buttons">
                            <div className="Bill-table-done-fade"></div>
                            { !isFinished && createdbyIsMe && <Button onClick={this.handleDeleteDoneClick(progress)}><Icon name="delete" fill="var(--blue)" /></Button> }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
        { !showDrillJobs && !showSawJobs &&
          <div><Text>{this.props.t('no_jobs')}</Text></div>
        }
        <div className="EndJob-extra">
          {
            !!other.length &&
            <div className="EndJob-extra-head">
              <div className="EndJob-extra-icon"><Icon fill="white" name="ellipsis" /></div>
              <Text weight="medium" color="white">Andere kosten</Text>
            </div>
          }
          { other.map(item => <OtherItem key={item.oc_seqwerkakost} item={item} isFinished={isFinished} isFuture={isFuture} lang={lang} onDelete={this.handleJobOtherDelete(item)} onUpdate={this.handleJobOtherUpdate(item)} />) }
        </div>
        <div className="Job-footer-button-container">
          { isFuture
            ? null
            : isFinished
              ? <AddPicturesToPlanning planning={planning} />
              : <>
                <Button color="green" onClick={this.handleEndClick}>
                  <div className="Job-footer-button">
                    <Icon name="check" fill="white" width={16} height={16} />
                    <Text color="white" weight="bold">{t('finish')}</Text>
                  </div>
                </Button>
                <Button color="orange" onClick={this.handleAddClick}>
                  <div className="Job-footer-button">
                    <Icon name="add" fill="white" width={16} height={16} />
                    <Text color="white" weight="bold">{t('add_item')}</Text>
                  </div>
                </Button>
              </>
          }
        </div>
      </>
    )
  }
}

const OtherItem = ({ item, isFinished, isFuture, lang, onDelete, onUpdate }) => {
  const [showEditModal, setShowEditModal] = useState(false)

  if (item.e_language !== lang) return null

  function handleOpen () {
    setShowEditModal(true)
  }

  function handleClose () {
    setShowEditModal(false)
  }

  return (
    <div className="EndJob-extra-row">
      { !isFinished && showEditModal && <JobOtherEditModal onCancel={handleClose} onSave={onUpdate} option={{ label: item.e_omschrijving, comment: item.oc_opmerking, amount: item.oc_aantal }} edit={true} /> }
      <div className="EndJob-extra-row-name">
        <Button onClick={isFuture ? () => {} : handleOpen}>
          <div className="EndJob-extra-row-content EndJob-extra-row-content-2">
            <div className="EndJob-extra-row-content-top">
              <div className="EndJob-extra-row-content-amount"><Text weight="bold" color="dark">{item.oc_aantal || ''}</Text></div>
              <div className="EndJob-extra-row-content-name"><Text weight="bold" color="dark">{item.e_omschrijving}</Text></div>
            </div>
            <div className="EndJob-extra-row-content-description"><Text color="dark" size="tiny">{item.oc_opmerking || ''}</Text></div>
          </div>
        </Button>
      </div>
      { !isFinished && !isFuture && <div className="EndJob-extra-row-delete">
        <Button onClick={onDelete}><Icon name="delete" fill="var(--grey-dark)" width={16} height={16} /></Button>
      </div> }
    </div>
  )
}

const BillWithT = withTranslation()(Bill)

export default BillWithT
