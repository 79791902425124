const initialState = {}
const template = {
  loading: false,
  progress: null,
  error: null
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_WORK_PROGRESS_LOADING': {
      return {
        ...state,
        [action.id]: {
          ...template,
          loading: true
        }
      }
    }
    case 'SET_WORK_PROGRESS': {
      return {
        ...state,
        [action.id]: {
          ...template,
          progress: action.progress
        }
      }
    }
    case 'SET_WORK_PROGRESS_ERROR': {
      return {
        ...state,
        [action.id]: {
          ...template,
          error: action.error
        }
      }
    }
    default: return state
  }
}

export default reducer
