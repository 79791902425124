import React from 'react'
import PropTypes from 'prop-types'
import './Textarea.css'

const Textarea = ({ placeholder, onChange, type, value, ...props }) =>
  <textarea
    className="Textarea"
    onChange={onChange}
    value={value}
    {...props}
  >
  </textarea>

Textarea.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default Textarea
