import React from 'react'
import './Loader.css'
import Icon from './Icon.js'
import PropTypes from 'prop-types'

const Loader = ({ size = 'normal', color = 'black', fullPage = false }) => {
  const classes = ['Loader']
  classes.push(`Loader-size-${size}`)
  classes.push(`Loader-color-${color}`)
  if (fullPage) classes.push('Loader-fullpage')
  return (
    <div className={classes.join(' ')}>
      <div className="Loader-circle">
        <Icon name="circle" />
      </div>
      <div className="Loader-circle Loader-circle-medium">
        <Icon name="circle" />
      </div>
      <div className="Loader-circle Loader-circle-small">
        <Icon name="circle" />
      </div>
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  color: PropTypes.oneOf(['black', 'white']),
  fullPage: PropTypes.bool
}

export default Loader
