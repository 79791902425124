import { getWorkProgress as fetchWorkProgress } from './connector.js'
import store from '../redux/store.js'
import { setWorkProgressLoading, setWorkProgress, setWorkProgressError } from '../redux/workProgress/actions.js'

async function getWorkProgress ({ seqwerk, token }) {
  const state = store.getState()
  if (state.workProgress.loading) return
  store.dispatch(setWorkProgressLoading(seqwerk))
  try {
    const workProgress = await fetchWorkProgress({ seqwerk, token })
    store.dispatch(setWorkProgress(seqwerk, workProgress))
  } catch (error) {
    console.error('error', error)
    store.dispatch(setWorkProgressError(seqwerk, error))
  }
}

export default getWorkProgress
