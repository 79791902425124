import React from 'react'
import PropTypes from 'prop-types'
import './Text.css'

const Text = ({ children, size = 'normal', weight = 'normal', color = 'text', ...props }) => {
  const classes = [
    'Text',
    `Text-size-${size}`,
    `Text-weight-${weight}`,
    `Text-color-${color}`
  ]
  return (
    <p className={classes.join(' ')} {...props}>
      {children}
    </p>
  )
}

Text.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['normal', 'tiny', 'extra-tiny', 'small', 'large']),
  weight: PropTypes.oneOf(['normal', 'medium', 'bold']),
  color: PropTypes.oneOf(['text', 'white', 'dark', 'purple', 'light', 'orange', 'purple-alt', 'blue'])
}

export default Text
