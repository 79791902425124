function setPlanningLoading () {
  return {
    type: 'SET_PLANNING_LOADING'
  }
}

function setPlanning (planning) {
  return {
    type: 'SET_PLANNING',
    planning
  }
}

function setPlanningError (error) {
  return {
    type: 'SET_PLANNING_ERROR',
    error
  }
}

export {
  setPlanningLoading,
  setPlanning,
  setPlanningError
}
