import { getPlanning as fetchPlanning } from './connector.js'
import store from '../redux/store.js'
import { setPlanningLoading, setPlanning, setPlanningError } from '../redux/planning/actions.js'

async function getPlanning ({ start, end, seqpers, token }) {
  const state = store.getState()
  if (state.planning.loading) return
  store.dispatch(setPlanningLoading())
  try {
    const planning = await fetchPlanning({ start, end, seqpers, token })
    store.dispatch(setPlanning(planning))
  } catch (error) {
    console.error('error', error)
    store.dispatch(setPlanningError(error))
  }
}

export default getPlanning
