import React, { Component } from 'react'
import Card from '../../components/card/Card.js'
import CardRow from '../../components/card/CardRow.js'
import Icon from '../../components/Icon.js'
import Text from '../../components/Text.js'
import { format } from 'date-fns'
import convertTimezone from '../../convertTimezone.js'

class WorkItem extends Component {
  render () {
    const {
      planning,
      onClick
    } = this.props
    const workers = planning.workers || []
    const address = `${planning.werf_gemeente || ''}, ${planning.werf_straat || ''} ${planning.werf_nummer || ''}`
    const planningVanDate = convertTimezone(new Date(planning.p_van))
    const planningTotDate = convertTimezone(new Date(planning.p_tot))
    const werkDetail = planning.werk_detail || []
    const drillDetail = werkDetail.filter(work => (work.det_typewerk || 'B').toUpperCase() === 'B')
    const sawDetail = werkDetail.filter(work => (work.det_typewerk || '').toUpperCase() === 'Z')
    return (
      <div className="Planning-work-item">
        <div className="Planning-work-title">
          <Icon name="clock" width={14} height={14} fill="var(--grey-text)" />
          <Text size="small">{format(planningVanDate, 'HH:mm')} - {format(planningTotDate, 'HH:mm')}</Text>
        </div>
        <Card onClick={onClick}>
          <CardRow>
            <div className="Planning-work-cardrow">
              <Text weight="bold">{planning.kla_bedrijf}</Text>
              <div className="Planning-work-workers"><Text weight="bold" color="dark">{workers.length}&nbsp;</Text><Icon name="worker" fill="var(--grey-dark)" width={18} height={18} /></div>
            </div>
          </CardRow>
          <CardRow size="small">
            <div className="Planning-work-cardrow"><Text size="small">{address}</Text></div>
          </CardRow>
          <CardRow size="grow">
            <div className="Planning-work-cardrow">
              <div className="Planning-work-info-left">
                {drillDetail.map(detail =>
                  <Text key={detail.det_id} size="small" color="purple" weight="bold">{detail.det_aantal} {detail.pos_positie_code} {detail.det_diameter} {detail.det_diepte}</Text>
                )}
                {sawDetail.map(detail =>
                  <Text key={detail.det_id} size="small" color="blue" weight="bold">{detail.det_aantal} {detail.pos_positie_code} {detail.det_lengte} {detail.det_diepte}</Text>
                )}
              </div>
              <div className="Planning-work-info-right">
                <Text size="small" color="dark">{planning.w_description}</Text>
              </div>
            </div>
          </CardRow>
        </Card>
      </div>
    )
  }
}

export default WorkItem
