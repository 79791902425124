function setPositionsLoading () {
  return {
    type: 'SET_POSITIONS_LOADING'
  }
}

function setPositions (positions) {
  return {
    type: 'SET_POSITIONS',
    positions
  }
}

function setPositionsError (error) {
  return {
    type: 'SET_POSITIONS_ERROR',
    error
  }
}

export {
  setPositionsLoading,
  setPositions,
  setPositionsError
}
