import React from 'react'
import store from '../redux/store.js'
import { Provider } from 'react-redux'
import './Wrapper.css'
import { getToken } from '../token.js'
import reloadPlanning from '../reloadPlanning.js'
import getExtra from '../connector/getExtra.js'
import getExtraPositions from '../connector/getExtraPositions.js'
import getPositions from '../connector/getPositions.js'
import Messages from './Messages.js'
import UpdateMessage from './UpdateMessage.js'

function reloadStore (lastUpdate) {
  let reload = false
  const now = new Date()
  reload = lastUpdate.getDate() !== now.getDate()
  if (!reload) {
    const oneDaySinceLastUpdate = lastUpdate.getTime() + (1000 * 60 * 60 * 24)
    reload = oneDaySinceLastUpdate < now.getTime()
  }
  return reload
}

function Wrapper ({ children }) {
  const { lastUpdate } = store.getState()
  if (reloadStore(lastUpdate)) {
    store.dispatch({ type: 'CLEAR_STORE' })
  }
  const { user, planning, extra, extraPositions, positions } = store.getState()
  if (!user.loading && user.user) {
    if (!planning.loading && !planning.planning && !planning.error) {
      reloadPlanning()
    }
    if (!extra.loading && !extra.extra && !extra.error) {
      getExtra({
        token: getToken()
      })
    }
    if (!extraPositions.loading && !extraPositions.extraPositions && !extraPositions.error) {
      getExtraPositions({
        token: getToken()
      })
    }
    if (!positions.loading && !positions.positions && !positions.error) {
      getPositions({
        token: getToken()
      })
    }
  }

  return (
    <Provider store={store}>
      <div className="Wrapper-height">
        {children}
      </div>
      <Messages />
      <UpdateMessage />
    </Provider>
  )
}

export default Wrapper
