import { getUser as fetchUser } from './connector.js'
import store from '../redux/store.js'
import { setUserLoading, setUser, setUserError } from '../redux/user/actions.js'

async function getUser ({ seqpers, token }) {
  const state = store.getState()
  if (state.user.loading) return
  store.dispatch(setUserLoading())
  try {
    const user = await fetchUser({ seqpers, token })
    store.dispatch(setUser(user[0]))
  } catch (error) {
    console.error('error', error)
    store.dispatch(setUserError(error))
  }
}

export default getUser
