import { getExtraPositions as fetchExtraPositions } from './connector.js'
import store from '../redux/store.js'
import { setExtraPositionsLoading, setExtraPositions, setExtraPositionsError } from '../redux/extraPositions/actions.js'

async function getExtraPositions ({ token }) {
  const state = store.getState()
  if (state.extraPositions.loading) return
  store.dispatch(setExtraPositionsLoading())
  try {
    const extraPositions = await fetchExtraPositions({ token })
    store.dispatch(setExtraPositions(extraPositions))
  } catch (error) {
    console.error('error', error)
    store.dispatch(setExtraPositionsError(error))
  }
}

export default getExtraPositions
