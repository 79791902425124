const initialState = {
  loading: false,
  extra: null,
  error: null
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_EXTRA_LOADING': {
      return {
        ...initialState,
        loading: true
      }
    }
    case 'SET_EXTRA': {
      return {
        ...initialState,
        extra: action.extra
      }
    }
    case 'SET_EXTRA_ERROR': {
      return {
        ...initialState,
        error: action.error
      }
    }
    default: return state
  }
}

export default reducer
