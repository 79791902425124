const initialState = {
  show: false
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_APP_UPDATE': {
      return {
        ...initialState,
        ...action.state
      }
    }
    default: return state
  }
}

export default reducer
