import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DropdownOption from './DropdownOption.js'
import Icon from '../Icon.js'
import Text from '../Text.js'
import './Dropdown.css'

class Dropdown extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selected: props.value || null,
      isOpen: false
    }

    this.handleHeadClick = this.handleHeadClick.bind(this)
    this.handleWindowClick = this.handleWindowClick.bind(this)

    this.isUnmounting = false
  }

  componentDidUpdate (prevProps, prevState) {
    let updateState = false
    if (this.props.value && prevProps.value && this.props.value.value !== prevProps.value.value) {
      updateState = true
    }
    if (this.props.value && !prevProps.value) {
      updateState = true
    }
    if (!this.props.value && prevProps.value) {
      updateState = true
    }
    if (updateState) {
      this.setState(state => ({ selected: this.props.value }))
    }
  }

  componentWillUnmount () {
    this.isUnmounting = true
    this.removeWindowListener()
  }

  removeWindowListener () {
    window.removeEventListener('click', this.handleWindowClick)
  }

  handleWindowClick (e) {
    const target = e.target
    if (!target.closest('.Dropdown')) {
      this.removeWindowListener()
      if (this.isUnmounting) return
      this.setState(state => ({
        isOpen: false
      }))
    }
  }

  handleOptionClick (value) {
    return e => {
      this.removeWindowListener()
      const newValue = this.props.options.find(option => option.value === value)
      this.setState(state => ({
        selected: newValue,
        isOpen: false
      }))
      this.props.onChange(newValue)
    }
  }

  handleHeadClick (e) {
    const newState = !this.state.isOpen
    this.setState(state => ({
      isOpen: newState
    }))
    if (newState) {
      window.addEventListener('click', this.handleWindowClick)
    } else {
      this.removeWindowListener()
    }
  }

  render () {
    const { options, icon, placeholder, color } = this.props
    const { selected, isOpen } = this.state
    const listClasses = ['Dropdown-list']
    if (isOpen) listClasses.push('Dropdown-list-open')
    return (
      <div className="Dropdown">
        <div className="Dropdown-head" onClick={this.handleHeadClick}>
          <div className="Dropdown-head-icon">
            {
              typeof icon === 'string'
                ? <Icon name={icon} fill="var(--grey-dark)" />
                : icon
            }
          </div>
          <div className="Dropdown-head-title">
            { selected
              ? <Text weight="bold" color="dark">{selected.label}</Text>
              : <Text color="light">{placeholder}</Text>
            }</div>
          <div className="Dropdown-head-icon"><Icon name="caretDown" width={10} height={10} fill="var(--grey-dark)" /></div>
        </div>
        <div className={listClasses.join(' ')}>
          {
            options.map(option => {
              return (
                <DropdownOption
                  key={option.value}
                  option={option}
                  onClick={this.handleOptionClick(option.value)}
                  active={selected && option.value === selected.value}
                  color={color}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string
  }),
  color: PropTypes.string
}

export default Dropdown
