import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon.js'
import Text from '../Text.js'
import './DropdownOption.css'

const DropdownOption = ({ option, onClick, active, color }) => {
  let currentColor = 'dark'
  let weight = 'normal'
  if (active) {
    currentColor = color || 'purple'
    weight = 'bold'
  }
  return (
    <div className="DropdownOption" onClick={onClick}>
      <div className="DropdownOption-label"><Text color={currentColor} weight={weight} size="normal">{option.label}</Text></div>
      { active && <div className="DropdownOption-check"><Icon name="check" fill={`var(--${currentColor})`} /></div> }
    </div>
  )
}

DropdownOption.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string
  }),
  onClick: PropTypes.func,
  active: PropTypes.bool,
  color: PropTypes.string
}

export default DropdownOption
