import React, { Component } from 'react'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import Icon from '../../components/Icon.js'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'
import { format, isAfter, startOfDay } from 'date-fns'
import './Info.css'
import LoadImage from '../../components/LoadImage.js'
import getContacts from '../../getContacts.js'
import AddPicturesToPlanning from '../../components/AddPicturesToPlanning/AddPicturesToPlanning.js'
import convertTimezone from '../../convertTimezone.js'

class Info extends Component {
  constructor (props) {
    super(props)

    this.handleCommentClick = this.handleCommentClick.bind(this)
  }

  handleCommentClick (e) {
    history.push(`/job/${this.props.planning.p_seqplan}/comment`)
  }

  handleCommentEditClick (comment) {
    return e => {
      history.push(`/job/${this.props.planning.p_seqplan}/editComment/${comment.seqopm}`)
    }
  }

  handleImageClick (image) {
    return e => {
      history.push(`/image/${this.props.planning.p_seqplan}/${image.seqafb}`)
    }
  }

  render () {
    const {
      planning,
      t,
      gotoBill
    } = this.props
    const address = `${planning.werf_gemeente || ''}, ${planning.werf_straat || ''} ${planning.werf_nummer || ''}`
    const werkDetail = planning.werk_detail || []
    const drillDetail = werkDetail.filter(work => (work.det_typewerk || 'B').toUpperCase() === 'B')
    const sawDetail = werkDetail.filter(work => (work.det_typewerk || '').toUpperCase() === 'Z')
    const comments = planning.opmerkingen
    const contacts = getContacts(planning)
    const workers = planning.workers || []
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${window.encodeURIComponent(address)}`
    const isFinished = planning.p_uitgevoerd
    const images = (planning.images || []).filter(image => !image.bij_opmerking)
    const planningVanDate = convertTimezone(new Date(planning.p_van))
    const planningTotDate = convertTimezone(new Date(planning.p_tot))
    const isFuture = isAfter(startOfDay(planningVanDate), startOfDay(new Date()))

    return (
      <>
        <div>
          <a className="Job-info-row Job-a" href={mapUrl} rel="noopener noreferrer" target="_blank">
            <div className="Job-info-row-icon"><Icon fill="var(--grey-dark)" name="pin" /></div>
            <div className="Job-info-row-label"><Text color="dark">{address}</Text></div>
            <div className="Job-info-row-icon"><Icon fill="var(--orange)" name="navigation" /></div>
          </a>
          <div className="Job-info-row">
            <div className="Job-info-row-icon"><Icon fill="var(--grey-dark)" name="clock" /></div>
            <div className="Job-info-row-label"><Text color="dark">{format(planningVanDate, 'HH:mm')} - {format(planningTotDate, 'HH:mm')}</Text></div>
          </div>
          <div className="Job-info-columns">
            <Button onClick={gotoBill}>
              <div className="Job-info-row-grow">
                <div className="Job-info-row-icon"><Icon fill="var(--purple)" name="drill" width={20} height={20} /></div>
                <div className="Job-info-row-label Job-info-row-label-column">
                  {
                    drillDetail.map(detail => {
                      return (
                        <Text key={detail.det_id} color="purple" weight="bold">{detail.det_aantal || '?'} {detail.pos_positie_code || '?'} {detail.det_diameter || '?'} {detail.det_diepte || '?'}</Text>
                      )
                    })
                  }
                </div>
              </div>
            </Button>
            <Button onClick={gotoBill}>
              <div className="Job-info-row-grow">
                <div className="Job-info-row-icon"><Icon fill="var(--blue)" name="saw" width={20} height={20} /></div>
                <div className="Job-info-row-label Job-info-row-label-column">
                  {
                    sawDetail.map(detail => {
                      return (
                        <Text key={detail.det_id} color="blue" weight="bold">{detail.det_aantal || '?'} {detail.pos_positie_code || '?'} {detail.det_lengte || '?'} {detail.det_diepte || '?'}</Text>
                      )
                    })
                  }
                </div>
              </div>
            </Button>
          </div>
          <div className="Job-info-row">
            <div className="Job-info-row-icon"><Icon fill="var(--grey-dark)" name="write" width={14} height={14} /></div>
            <div className="Job-info-row-label"><Text color="dark" size="small">{planning.w_opmerking_intern}</Text></div>
          </div>
          {
            contacts.map(contact => {
              return (
                <a className="Job-info-row Job-a" key={contact.id} href={`tel:${contact.telefoon || ''}`}>
                  <div className="Job-info-row-icon"><Icon fill="var(--grey-dark)" name="manager" width={18} height={18} /></div>
                  <div className="Job-info-row-label"><Text color="dark">{contact.naam || ''}</Text></div>
                  <div className="Job-info-row-label Job-info-row-label-right"><Text color="dark" size="small">{contact.telefoon || ''}</Text></div>
                  <div className="Job-info-row-icon"><Icon fill="var(--orange)" name="phone" /></div>
                </a>
              )
            })
          }
          {
            workers.map(worker => {
              const mobile = worker.worker_mobile || ''
              return (
                <div className="Job-info-row" key={worker.worker_id}>
                  <div className="Job-info-row-icon"><Icon fill="var(--grey-dark)" name="worker" /></div>
                  <div className="Job-info-row-label"><Text color="dark">{`${worker.worker_voornaam || ''} ${worker.worker_achternaam || ''}`}</Text></div>
                  <div className="Job-info-row-label Job-info-row-label-right"><Text color="dark" size="small">{mobile}</Text></div>
                  { mobile && <div className="Job-info-row-icon"><a className="Job-a" href={`tel:${mobile}`}><Icon fill="var(--orange)" name="phone" /></a></div> }
                </div>
              )
            })
          }
        </div>
        <div className="Job-pictures">
          { images.map(image => {
            return (
              <div key={image.seqafb} className="Job-picture-container">
                <Button onClick={this.handleImageClick(image)}>
                  <LoadImage seqafb={image.seqafb} />
                </Button>
              </div>
            )
          }) }
        </div>
        <div className="Job-comments">
          {
            comments.map(comment => {
              return (
                <Button key={comment.seqopm} onClick={isFuture ? () => {} : this.handleCommentEditClick(comment)}>
                  <div className="Job-comment">
                    <div className="Job-comment-icon"><Icon name="comment" fill="var(--grey-dark)" /></div>
                    <div className="Job-comment-text"><Text color="dark">{comment.opmerking}</Text></div>
                    <div className="Job-comment-icon"><Icon fill="var(--orange)" name="pencil" width={14} height={14} /></div>
                  </div>
                </Button>
              )
            })
          }
        </div>
        <div className="Job-footer-button-container">
          { isFuture
            ? null
            : isFinished
              ? <AddPicturesToPlanning planning={planning} />
              : <>
                <Button color="orange" onClick={this.handleCommentClick}>
                  <div className="Job-footer-button">
                    <Icon name="comment" fill="white" width={16} height={16} />
                    <Text color="white" weight="bold">{t('add_comment')}</Text>
                  </div>
                </Button>
              </>
          }
        </div>
      </>
    )
  }
}

const InfoWithT = withTranslation()(Info)

export default InfoWithT
