import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import nl from './languages/nl_be.json'
// import en from './languages/en_gb.json'
import LanguageDetector from 'i18next-browser-languagedetector'

const validLanguages = new Set(['nl'])

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: [...validLanguages][0],
    ns: ['common'], // namespaces
    defaultNS: 'common',
    whitelist: [...validLanguages],
    resources: {
      nl: { common: nl }
      // en: { common: en }
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, function (error, t) {
    if (error) {
      console.warn('i18next error', error)
    }
  })

export default i18n
export { validLanguages }
