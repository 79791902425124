import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './Input.css'

const Input = ({ placeholder, onChange, type, value, size = 'large', id, ...props }) => {
  const [inputValue, setInputValue] = useState(value || '')
  useEffect(() => {
    setInputValue(value || '')
  }, [value])
  const classes = [
    'Input',
    `Input-size-${size}`
  ]
  function handleChange (e) {
    setInputValue(e.target.value)
    onChange(e)
  }
  return (
    <input
      className={classes.join(' ')}
      id={id}
      placeholder={placeholder}
      type={type}
      onChange={handleChange}
      value={inputValue}
      {...props}
    />
  )
}

Input.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  size: PropTypes.oneOf(['small', 'large'])
}

export default Input
