import React, { Component } from 'react'
import './EndJob.css'
import Header from '../../components/Header.js'
import Text from '../../components/Text.js'
import Icon from '../../components/Icon.js'
import Button from '../../components/Button.js'
import Input from '../../components/Input.js'
import Dropdown from '../../components/dropdown/Dropdown.js'
import { withTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import { connect } from 'react-redux'
import getWorkProgress from '../../connector/getWorkProgress.js'
import { getToken } from '../../token.js'
import reloadPlanning from '../../reloadPlanning.js'
import Loader from '../../components/Loader.js'
import Camera from '../../components/Camera.js'
import LoadImage from '../../components/LoadImage.js'
import { uploadFile, deleteFile, updatePlanning } from '../../connector/connector.js'
import { addMessage } from '../../components/Messages.js'
import history from '../../history.js'
import getContacts from '../../getContacts.js'
import i18n from '../../i18n.js'

class EndJob extends Component {
  constructor (props) {
    super(props)

    let workProgress = []
    if (props.workProgress) {
      workProgress = props.workProgress.progress || []
    }

    this.state = {
      workProgress,
      signOption: 'other',
      loading: false,
      pictures: [],
      takePicture: false,
      currentSignature: '',
      signerName: ''
    }

    this.handleContactChange = this.handleContactChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handlePictureSelectClick = this.handlePictureSelectClick.bind(this)
    this.handleCanvasClearClick = this.handleCanvasClearClick.bind(this)
    this.setPicture = this.setPicture.bind(this)
    this.handlePictureCreateClick = this.handlePictureCreateClick.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleDrawEnd = this.handleDrawEnd.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.canvasRef = React.createRef()
    this.fileRef = React.createRef()
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    this.getMyWorkProgress() // always update workProgress redux state when loading this view
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.loading && prevProps.loading && !this.props.workProgress) { // this way when refreshing the page, it'll load workProgress once when planning is ready
      this.getMyWorkProgress()
    }
    if (!this.props.workProgress || !prevProps.workProgress) return
    if (!this.props.workProgress.loading && prevProps.workProgress.loading) {
      this.setState(state => ({ workProgress: this.props.workProgress.progress }))
    }
  }

  getMyWorkProgress () {
    if (this.props.loading) return
    const token = getToken()
    const seqwerk = this.props.planning.w_seqwerk
    getWorkProgress({ seqwerk, token })
  }

  async handleSaveClick (e) {
    const { signOption, signerName, currentSignature } = this.state
    const signatureIsRequired = signOption !== 'client_not_present'
    const nameIsRequired = signOption === 'other'
    const readyToFinish = (signatureIsRequired ? currentSignature : true) && (nameIsRequired ? signerName : true)
    if (!readyToFinish) return

    const canvas = this.canvasRef.current
    const signature = await new Promise((resolve, reject) => canvas.getCanvas().toBlob(resolve, 'image/png'))
    const { pictures } = this.state
    const token = getToken()
    const { planning, user } = this.props
    const seqwerk = planning.w_seqwerk
    const existingImagesCount = (planning.images || []).length
    const seqpers = user.seqpers

    this.setState(state => ({ loading: true }))

    // upload all images
    const picturePromises = pictures.map((picture, i) => {
      if (picture.deleted) {
        return deleteFile({
          seqafb: picture.picture.insertId,
          token
        })
      } else if (picture.type === 'new') {
        return uploadFile({
          seqwerk,
          file: picture.picture,
          filename: `afronden_${seqwerk}_${seqpers}_${existingImagesCount + i}.png`,
          seqpers,
          token
        })
      }
      return false
    })
    picturePromises.push(uploadFile({
      seqwerk,
      file: signature,
      filename: `handtekening_${seqwerk}_${seqpers}_${existingImagesCount + pictures.length}.png`,
      seqpers,
      isSignature: true,
      token
    }))
    try {
      await Promise.all(picturePromises)
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    // end job
    try {
      await updatePlanning({
        update: {
          uitgevoerd: true
        },
        seqpers,
        seqwerk: planning.w_seqwerk,
        van: planning.p_van,
        token
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    await reloadPlanning()
    history.go(-2)
  }

  handleContactChange (newValue) {
    console.log('handleContactChange', newValue)
    this.setState(state => ({ signOption: newValue.value }))
  }

  handleInputChange (e) {
    const newValue = e.target.value
    this.setState(state => ({ signerName: newValue }))
  }

  handlePictureSelectClick (e) {
    if (this.state.loading) return
    const input = this.fileRef.current
    if (!input) return
    this.fileRef.current.click()
  }

  handlePictureCreateClick (e) {
    if (this.state.loading) return
    this.setState(state => ({ takePicture: true }))
  }

  async handleFileChange (e) {
    if (this.state.loading) return
    const file = e.target.files[0]
    if (!file) return

    this.setState(state => ({ loading: true }))

    const { planning, user } = this.props
    const seqwerk = planning.w_seqwerk
    const seqpers = user.seqpers

    const filename = file.name
    const splitname = filename.split('.')
    const nameWithoutExtension = splitname.slice(0, -1).join('.')
    const finalFilename = [`${nameWithoutExtension}_afronden_${seqwerk}_${seqpers}_${(planning.images || []).length + this.state.pictures.length}`, splitname.slice(-1)[0]].join('.')

    let response
    try {
      response = await uploadFile({
        file,
        filename: finalFilename,
        seqwerk,
        seqpers,
        token: getToken()
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      this.setState(state => ({ loading: false }))
      return
    }

    const pictures = [...this.state.pictures]
    pictures.push({
      type: 'uploaded',
      picture: response
    })
    if (this.isUnmounting) return
    this.setState(state => ({
      pictures,
      loading: false
    }))
  }

  handleCanvasClearClick (e) {
    const canvas = this.canvasRef.current
    canvas.clear()
    this.setState(state => ({ currentSignature: '' }))
  }

  setPicture (picture) {
    const pictures = [...this.state.pictures]
    pictures.push({
      type: 'new',
      picture
    })
    this.setState(state => ({
      takePicture: false,
      pictures
    }))
  }

  handlePictureDeleteClick (index) {
    return async e => {
      const pictures = this.state.pictures
      const myPicture = pictures[index]
      let newPictures
      switch (myPicture.type) {
        case 'online':
        case 'uploaded': {
          newPictures = pictures.map((picture, i) => {
            if (i === index) {
              return {
                ...picture,
                deleted: true
              }
            }
            return picture
          })
          break
        }
        case 'new': {
          newPictures = [...pictures.slice(0, index), ...pictures.slice(index + 1)]
          break
        }
        default: {
          console.warn(`unrecognised image type: ${myPicture.type}`)
          addMessage({
            type: 'error',
            message: this.props.t('unrecognised_image_type')
          })
          break
        }
      }
      this.setState(state => ({
        pictures: newPictures
      }))
    }
  }

  handleDrawEnd (e) {
    this.setState(state => ({ currentSignature: this.canvasRef.current.toDataURL() }))
  }

  handleEnterPress (e) {
    if (e.key === 'Enter') {
      e.target.blur()
    }
  }

  render () {
    const {
      t,
      planning
    } = this.props
    const {
      signOption,
      workProgress,
      loading,
      takePicture,
      pictures,
      currentSignature,
      signerName
    } = this.state
    const previousImages = (planning.images || []).filter(image => !image.bij_opmerking)
    const workDetail = planning.werk_detail || []
    const drillDetail = workDetail.filter(work => (work.det_typewerk || 'B').toUpperCase() === 'B')
    const sawDetail = workDetail.filter(work => (work.det_typewerk || '').toUpperCase() === 'Z')
    const other = planning.otherCosts || []

    const contacts = getContacts(planning)
    const phone = (contacts[0] || {}).telefoon
    const options = [
      {
        value: 'other',
        label: t('other')
      },
      {
        value: 'client_not_present',
        label: t('client_not_present')
      },
      ...contacts.map(contact => ({
        value: contact.id,
        label: contact.naam
      }))
    ]

    const signatureIsRequired = signOption !== 'client_not_present'
    const nameIsRequired = signOption === 'other'
    const readyToFinish = (signatureIsRequired ? currentSignature : true) && (nameIsRequired ? signerName : true)

    const lang = i18n.language

    return (
      <div>
        { loading && <Loader fullPage={true} /> }
        { takePicture && <Camera setPicture={this.setPicture} goBack={() => this.setState(state => ({ takePicture: false }))} /> }
        <input className="EndJob-file-input" ref={this.fileRef} type="file" onChange={this.handleFileChange} />
        <Header backButton={true} shadow={true} size="large">
          <div className="EndJob-header">
            <div className="EndJob-header-title">
              <Text size="large" weight="bold">{t('finish_job')}</Text>
              <Text color="dark">{planning.kla_bedrijf}</Text>
            </div>
            { phone
              ? <a className="EndJob-header-icon" href={`tel:${phone}`}><Icon name="phone" fill="var(--grey-dark)" /></a>
              : <div className="EndJob-header-icon"></div>
            }
          </div>
        </Header>
        <div className="EndJob-scroll">
          {/* drilljobs start */}
          {!!drillDetail.length && <>
            <div className="EndJob-head">
              <div className="EndJob-head-icon"><Icon name="drill" fill="white" width={20} height={20} /></div>
              <Text color="white" weight="bold">Boorwerk</Text>
            </div>
            <div className="EndJob-table-head">
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="poundSign" />
                <Text size="tiny" color="dark">Aantal</Text>
              </div>
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="box" />
                <Text size="tiny" color="dark">Positie</Text>
              </div>
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="diameter" />
                <Text size="tiny" color="dark">Diameter</Text>
              </div>
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="depth" />
                <Text size="tiny" color="dark">Diepte</Text>
              </div>
            </div>
            <div className="EndJob-table-body">
              {
                drillDetail.map(detail => {
                  const id = detail.det_id
                  const done = workProgress.filter(progress => progress.pro_id_t_werk_detail === id)
                  return (
                    <div key={detail.det_id} className="EndJob-table-body-row">
                      <div className="EndJob-table-row-top">
                        <div className="EndJob-table-body-amount">
                          <Text color="purple" weight="bold">{done.reduce((accumulator, currentValue) => accumulator + currentValue.pro_aantal, 0)}x</Text>
                        </div>
                        <div className="EndJob-table-body-rest">
                          <div className="EndJob-table-body-rest-container">
                            <div className="EndJob-table-body-rest-cell">
                              <Text color="purple" weight="bold">{detail.pos_positie_code || '?'}</Text>
                            </div>
                            <div className="EndJob-table-body-rest-cell">
                              <Text color="purple" weight="bold">{detail.det_diameter || '?'}</Text>
                              <Text color="purple" size="tiny">mm</Text>
                            </div>
                            <div className="EndJob-table-body-rest-cell">
                              <Text color="purple" weight="bold">{detail.det_diepte || '?'}</Text>
                              <Text color="purple" size="tiny">cm</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="EndJob-table-body-extra">
                        {
                          (detail.extra || []).map(extra => {
                            return (
                              <div key={extra.id} className="EndJob-table-body-extra-row"><Text color="purple-alt" size="extra-tiny">+ {extra.omschrijving_toeslag}</Text></div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </> }
          {/* drilljobs end */}
          {/* sawjobs start */}
          {!!sawDetail.length && <div className="EndJob-saw">
            <div className="EndJob-head">
              <div className="EndJob-head-icon"><Icon name="saw" fill="white" width={20} height={20} /></div>
              <Text color="white" weight="bold">Zaagwerk</Text>
            </div>
            <div className="EndJob-table-head">
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="poundSign" />
                <Text size="tiny" color="dark">Aantal</Text>
              </div>
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="box" />
                <Text size="tiny" color="dark">Positie</Text>
              </div>
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="length" />
                <Text size="tiny" color="dark">Lengte</Text>
              </div>
              <div className="EndJob-table-head-title">
                <Icon fill="var(--grey-dark)" name="depth" />
                <Text size="tiny" color="dark">Diepte</Text>
              </div>
            </div>
            <div className="EndJob-table-body">
              {
                sawDetail.map(detail => {
                  const id = detail.det_id
                  const done = workProgress.filter(progress => progress.pro_id_t_werk_detail === id)
                  return (
                    <div key={detail.det_id} className="EndJob-table-body-row">
                      <div className="EndJob-table-row-top">
                        <div className="EndJob-table-body-amount">
                          <Text color="blue" weight="bold">{done.reduce((accumulator, currentValue) => accumulator + currentValue.pro_aantal, 0)}x</Text>
                        </div>
                        <div className="EndJob-table-body-rest">
                          <div className="EndJob-table-body-rest-container">
                            <div className="EndJob-table-body-rest-cell">
                              <Text color="blue" weight="bold">{detail.pos_positie_code || '?'}</Text>
                            </div>
                            <div className="EndJob-table-body-rest-cell">
                              <Text color="blue" weight="bold">{detail.det_lengte || '?'}</Text>
                              <Text color="blue" size="tiny">m</Text>
                            </div>
                            <div className="EndJob-table-body-rest-cell">
                              <Text color="blue" weight="bold">{detail.det_diepte || '?'}</Text>
                              <Text color="blue" size="tiny">cm</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="EndJob-table-body-extra">
                        {
                          (detail.extra || []).map(extra => {
                            return (
                              <div key={extra.id} className="EndJob-table-body-extra-row"><Text color="blue" size="extra-tiny">+ {extra.omschrijving_toeslag}</Text></div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div> }
          {/* sawjobs end */}
          <div className="EndJob-extra">
            {
              !!other.length &&
              <div className="EndJob-extra-head">
                <div className="EndJob-extra-icon"><Icon fill="white" name="ellipsis" /></div>
                <Text weight="medium" color="white">Andere kosten</Text>
              </div>
            }
            {
              other.map(item => {
                if (item.e_language !== lang) return null
                return (
                  <div key={item.oc_seqwerkakost} className="EndJob-extra-row">
                    <div className="EndJob-extra-row-name">
                      <div className="EndJob-extra-row-content">
                        <div className="EndJob-extra-row-content-top">
                          <div className="EndJob-extra-row-content-amount"><Text weight="bold" color="dark">{item.oc_aantal || ''}</Text></div>
                          <div className="EndJob-extra-row-content-name"><Text weight="bold" color="dark">{item.e_omschrijving}</Text></div>
                        </div>
                        <div className="EndJob-extra-row-content-description"><Text color="dark" size="tiny">{item.oc_opmerking || ''}</Text></div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="EndJob-pictures">
            <div className="EndJob-pictures-buttons">
              <Button onClick={this.handlePictureSelectClick} color="orange">
                <div className="EndJob-pictures-button">
                  <Icon width={20} height={20} name="gallery" fill="white" />
                  <Text color="white" weight="bold">{t('choose_picture')}</Text>
                </div>
              </Button>
              <Button onClick={this.handlePictureCreateClick} color="orange">
                <div className="EndJob-pictures-button">
                  <Icon width={20} height={20} name="camera" fill="white" />
                  <Text color="white" weight="bold">{t('take_picture')}</Text>
                </div>
              </Button>
            </div>
            <div className="EndJob-pictures-gallery">
              { pictures.map((picture, i) => {
                if (picture.deleted) return null
                return (
                  <div key={i} className="EndJob-picture-container">
                    { picture.type === 'new'
                      ? <LoadImage blob={picture.picture} />
                      : <LoadImage seqafb={picture.picture.insertId || picture.picture.seqafb} />
                    }
                    <div className="EndJob-picture-delete">
                      <Button onClick={this.handlePictureDeleteClick(i)}>
                        <Icon name="delete" width={12} height={12} />
                      </Button>
                    </div>
                  </div>
                )
              }) }
              { previousImages.map(picture => {
                return (
                  <div key={picture.seqafb} className="EndJob-picture-container">
                    <LoadImage seqafb={picture.seqafb} />
                  </div>
                )
              }) }
            </div>
          </div>
          <div className="EndJob-sign">
            <Dropdown
              value={options[0]}
              options={options}
              icon={<div className="EndJob-sign-icons"><Icon fill="var(--grey-text)" name="signature" /><Icon fill="var(--grey-text)" name="pencil" /></div>}
              onChange={this.handleContactChange}
              placeholder={t('select_signer')}
            />
            { signOption === 'other' && (
              <div className="EndJob-sign-input">
                <Input size="small" placeholder={t('name_signer')} onChange={this.handleInputChange} onKeyPress={this.handleEnterPress} />
              </div>
            ) }
            <div className="EndJob-sign-canvas-container">
              <SignatureCanvas
                ref={this.canvasRef}
                penColor='#707070'
                canvasProps={{ className: 'EndJob-canvas' }}
                minWidth={1}
                maxWidth={3}
                onEnd={this.handleDrawEnd}
                clearOnResize={false}
              />
              <div className="EndJob-sign-canvas-clear-button">
                <Button onClick={this.handleCanvasClearClick}><Icon name="cross" fill="var(--grey-text)" width={22} height={22} /></Button>
              </div>
            </div>
          </div>
        </div>
        <div className="EndJob-button">
          <Button onClick={this.handleSaveClick} color={readyToFinish ? 'green' : 'grey'}>{t('finish')}</Button>
        </div>
      </div>
    )
  }
}

const EndJobWithT = withTranslation()(EndJob)

function mapStateToProps (state, props) {
  const seqplan = props.seqplan
  const planning = (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan) || {}
  const loading = state.planning.loading
  return {
    loading,
    planning,
    workProgress: planning ? state.workProgress[planning.w_seqwerk] : null,
    user: state.user.user
  }
}

const EndJobConnect = connect(mapStateToProps)(EndJobWithT)

export default EndJobConnect
