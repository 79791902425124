import React, { Component } from 'react'
import './Camera.css'
import { withTranslation } from 'react-i18next'
import Header from '../components/Header.js'
import Text from '../components/Text.js'
import Button from '../components/Button.js'
import Loader from '../components/Loader.js'
import { addMessage } from '../components/Messages.js'
import history from '../history.js'

class Camera extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false
    }

    this.videoRef = React.createRef()
    this.canvasRef = React.createRef()

    this.height = 0
    this.width = 320
    this.streaming = false
    this.stream = null
    this.isUnmounting = false

    this.handleCapture = this.handleCapture.bind(this)
  }

  componentDidMount () {
    this.initVideo()
    this.unblock = history.block(() => {
      this.props.goBack()
      return 'camera'
    })
  }

  componentWillUnmount () {
    this.isUnmounting = true
    this.stopVideo()
    this.unblock()
  }

  initVideo () {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      addMessage({
        type: 'error',
        message: this.props.t('getUserMedia_not_supported')
      })
      this.props.goBack()
      return
    }

    this.setState(state => ({ loading: true }))

    const video = this.videoRef.current

    video.addEventListener('canplay', event => {
      if (this.isUnmounting) return
      if (!this.streaming) {
        video.play()

        const trackSettings = this.stream.getVideoTracks()[0].getSettings()
        this.width = trackSettings.width
        this.height = trackSettings.height
        const canvas = this.canvasRef.current
        canvas.setAttribute('width', trackSettings.width)
        canvas.setAttribute('height', trackSettings.height)

        this.streaming = true
        this.setState(state => ({ loading: false }))
      }
    }, false)

    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'environment',
        width: 1280,
        height: 720
      },
      audio: false
    }).then(stream => {
      if (this.isUnmounting) {
        try {
          stream.getTracks()[0].stop()
        } catch (error) {
          console.error(error)
        }
        return
      }
      this.stream = stream
      video.srcObject = stream
    }).catch(error => {
      console.error(error)
      addMessage({
        type: 'error',
        message: this.props.t('getUserMedia_failed')
      })
      this.setState(state => ({ loading: false }))
    })
  }

  stopVideo () {
    if (this.stream) {
      const track = (this.stream.getTracks() || [])[0]
      track && track.stop()
    }
  }

  takePicture () {
    const canvas = this.canvasRef.current
    const video = this.videoRef.current
    const { width, height } = this
    const context = canvas.getContext('2d')
    if (width && height) {
      canvas.width = width
      canvas.height = height
      context.drawImage(video, 0, 0, width, height)

      const data = new Promise((resolve, reject) => canvas.toBlob(resolve, 'image/png'))
      return data
    } else {
      addMessage({
        type: 'error',
        message: this.props.t('camera_hasnt_loaded_yet')
      })
      return ''
    }
  }

  async handleCapture () {
    const picture = await this.takePicture()
    if (!picture) return
    this.props.setPicture(picture)
  }

  render () {
    const t = this.props.t
    const loading = this.state.loading
    return (
      <div className="Camera">
        <Header backButton={true} size="small" onBackPress={this.props.goBack}>
          <div className="Camera-header">
            <Text size="large" weight="bold">{t('take_picture')}</Text>
          </div>
        </Header>
        <div className="Camera-container">
          { loading && <Loader /> }
          <video className="Camera-video" playsInline={true} ref={this.videoRef}>{t('no_camera')}</video>
        </div>
        <div className="Camera-button">
          <Button color="orange" onClick={this.handleCapture}>Foto nemen</Button>
        </div>
        <canvas ref={this.canvasRef} className="Camera-canvas">
        </canvas>
      </div>
    )
  }
}

const CameraWithT = withTranslation()(Camera)

export default CameraWithT
