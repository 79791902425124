import React, { Component } from 'react'
import './Job.css'
import { connect } from 'react-redux'
import Header from '../../components/Header.js'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import Icon from '../../components/Icon.js'
import Loader from '../../components/Loader.js'
import Info from './Info.js'
import Bill from './Bill.js'
import { setJobTab as setJobTabAction } from '../../redux/jobPage/actions.js'

class Job extends Component {
  handleTabClick (name) {
    return e => {
      if (this.props.activeTab === name) return
      this.props.setJobTab(name)
    }
  }

  render () {
    const {
      planning,
      workProgress,
      user
    } = this.props
    if (!planning) {
      return (<>
        <Header size="small" backButton={true}><div className="Job-title"><Text size="large" weight="bold" color="dark"></Text></div></Header>
        <div className="Job-loading"><Loader /></div>
      </>)
    }

    const activeTab = this.props.activeTab
    let view
    switch (activeTab) {
      case 'info': {
        view = <Info planning={planning} gotoBill={this.handleTabClick('bill')} />
        break
      }
      case 'bill': {
        view = <Bill planning={planning} workProgress={workProgress} user={user} />
        break
      }
      default:
    }

    return (
      <div className="Job">
        <Header size="small" backButton={true}><div className="Job-title"><Text size="large" weight="bold" color="dark">{planning.kla_bedrijf}</Text></div></Header>
        <div className="Job-tabs">
          <div className={activeTab === 'info' ? 'Job-tabs-active' : ''}>
            <Button onClick={this.handleTabClick('info')}>
              <Icon name="i" width={20} height={20} fill={activeTab === 'info' ? 'var(--orange)' : 'var(--grey-text)'} />
              {activeTab === 'info' && <div className="Job-tabs-active-underline"></div>}
            </Button>
          </div>
          <div className={activeTab === 'bill' ? 'Job-tabs-active' : ''}>
            <Button onClick={this.handleTabClick('bill')}>
              <Icon name="bill" width={20} height={20} fill={activeTab === 'bill' ? 'var(--orange)' : 'var(--grey-text)'} />
              {activeTab === 'bill' && <div className="Job-tabs-active-underline"></div>}
            </Button>
          </div>
        </div>
        <div className="Job-scroll">
          {view}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const seqplan = props.seqplan
  const planning = (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan)
  return {
    planning,
    activeTab: state.jobPage.tab,
    workProgress: planning ? state.workProgress[planning.w_seqwerk] : null,
    user: state.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setJobTab: tab => dispatch(setJobTabAction(tab))
  }
}

const JobConnect = connect(mapStateToProps, mapDispatchToProps)(Job)

export default JobConnect
