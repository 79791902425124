import { getPositions as fetchPositions } from './connector.js'
import store from '../redux/store.js'
import { setPositionsLoading, setPositions, setPositionsError } from '../redux/positions/actions.js'

async function getPositions ({ token }) {
  const state = store.getState()
  if (state.positions.loading) return
  store.dispatch(setPositionsLoading())
  try {
    const positions = await fetchPositions({ token })
    store.dispatch(setPositions(positions))
  } catch (error) {
    console.error('error', error)
    store.dispatch(setPositionsError(error))
  }
}

export default getPositions
