import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  getUserConfirmation (message, callback) {
    if (message === 'camera') {
      // eslint-disable-next-line
      callback(false)
    } else {
      callback(window.confirm(message))
    }
  }
})

export default history
