import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
// import './fonts/inter.css'
import UniversalRouter from 'universal-router'
import routes from './routes.js'
import Wrapper from './components/Wrapper.js'
import history from './history.js'
// import NotFound from './pages/notFound/NotFound.js'
// import { notify } from './components/Notifier.js'
import { getToken, removeToken } from './token.js'
import store from './redux/store.js'
import { validate } from './connector/connector.js'
import getUser from './connector/getUser.js'
import notifyUpdate from '@appsaloon/pwa-check-update'
import { setAppUpdate } from './redux/appUpdate/actions.js'

notifyUpdate({
  onUpdateIsAvailable () {
    store.dispatch(setAppUpdate({ show: true }))
  }
})

const notify = console.log
const NotFound = () => <div>nothing here</div>

const options = {
  errorHandler (error, context) {
    console.error(error)
    console.info(context)
    if (error.status === 404) {
      return NotFound()
    } else {
      notify('error', 'Error', error.toString())
      return null
    }
  }
}

const router = new UniversalRouter(routes, options)

const root = document.getElementById('root')
function render (location) {
  // verify auth token
  // if ok
  //   if pathname === login
  //   redirect to /
  // if nok
  //   if (pathname !== login)
  //   redirect to /login

  const destination = location.pathname

  const token = getToken()
  const noAuthDestinations = new Set(['/login'])
  if (!token && !noAuthDestinations.has(destination)) {
    // notify('info', 'redirect', `redirected from ${destination}`)
    return history.push('/login')
  } else if (token && noAuthDestinations.has(destination)) {
    // notify('info', 'redirect', `redirected from ${destination}`)
    return history.push('/')
  }

  const currentUser = store.getState().user

  router.resolve({
    pathname: destination,
    context: {
      user: currentUser
    }
  }).then(component => {
    ReactDOM.render(
      <Wrapper>{component}</Wrapper>,
      root
    )
  })
}

// Listen for changes to the current location.
// const unlisten =
history.listen((location, action) => {
  render(location)
})

// To stop listening, call the function returned from listen().
// unlisten()

// Render the app for the first time
async function initialize () {
  const token = getToken()
  if (!token) {
    return render(history.location)
  }

  let response
  try {
    // validate token
    response = await validate(token)
  } catch (error) {
    return alert(error.toString())
  }

  if (response === true) {
    let seqpers
    try { // get seqpers
      const body = token.split('.')[1]
      const decoded = window.atob(body)
      const parsed = JSON.parse(decoded)
      seqpers = parsed.user
      await getUser({ seqpers, token })
    } catch (error) {
      console.warn('couldn\'t get seqpers from token')
      window.alert('invalid token')
      removeToken()
      history.push('/login')
    }
  // } else if (response.status === 401) {
  } else if (response === false) {
    removeToken()
    history.push('/login')
  } else {
    console.warn('unexpected response status', response)
    console.warn('token', token)
    removeToken()
    history.push('/login')
  }

  render(history.location)
}

initialize()
