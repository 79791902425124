import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button.js'
import Icon from './Icon.js'
import './Header.css'
import history from '../history.js'

const Header = ({ children, backButton, shadow, color = 'light', size = 'normal', onBackPress }) => {
  const classes = [
    'Header',
    `Header-size-${size}`,
    `Header-color-${color}`
  ]
  if (shadow) classes.push('Header-shadow')
  onBackPress = onBackPress || history.goBack
  return (
    <div className={classes.join(' ')}>
      {backButton && <BackButton onBackPress={onBackPress} />}
      {children}
    </div>
  )
}

const BackButton = ({ onBackPress }) =>
  <div className="Header-button">
    <Button onClick={onBackPress}>
      <Icon name="caretLeft" />
    </Button>
  </div>

Header.propTypes = {
  children: PropTypes.node,
  backButton: PropTypes.bool,
  shadow: PropTypes.bool,
  color: PropTypes.oneOf(['dark', 'light', 'purple', 'very-dark', 'blue']),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  onBackPress: PropTypes.func
}

export default Header
