function setExtraLoading () {
  return {
    type: 'SET_EXTRA_LOADING'
  }
}

function setExtra (extra) {
  return {
    type: 'SET_EXTRA',
    extra
  }
}

function setExtraError (error) {
  return {
    type: 'SET_EXTRA_ERROR',
    error
  }
}

export {
  setExtraLoading,
  setExtra,
  setExtraError
}
