import React from 'react'
import PropTypes from 'prop-types'
import './Card.css'

const Card = ({ children, ...props }) => {
  return (
    <div className="Card" {...props}>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node
}

export default Card
