import React from 'react'
import Icon from '../../components/Icon.js'
import Text from '../../components/Text.js'
import './FinishedWorkItem.css'

const FinishedWorkItem = ({ planning, onClick }) => {
  const address = `${planning.werf_gemeente || ''}, ${planning.werf_straat || ''} ${planning.werf_nummer || ''}`
  return (
    <div className="FinishedWorkItem" onClick={onClick}>
      <div className="FinishedWorkItem-icon"><Icon name="pin" width={14} height={14} /></div>
      <div className="FinishedWorkItem-fill"><Text color="white" size="normal" weight="bold">{address}</Text></div>
      <div className="FinishedWorkItem-icon"><Icon name="check" width={16} height={16} /></div>
    </div>
  )
}

export default FinishedWorkItem
