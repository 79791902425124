import { format, addDays, startOfDay } from 'date-fns'

function getPlanningRange (days) {
  let date
  if (process.env.NODE_ENV === 'production') {
    date = startOfDay(new Date())
  } else {
    date = startOfDay(new Date(2022, 3, 14))
  }
  const start = format(date, 'yyyy-MM-dd')
  const end = format(addDays(date, days), 'yyyy-MM-dd')

  const dates = []
  for (let x = -1; x++ < days;) {
    dates.push(addDays(date, x).getTime())
  }

  return { start, end, dates }
}

export default getPlanningRange
