const initialState = {
  loading: false,
  otherCosts: null,
  error: null
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_OTHER_COSTS_LOADING': {
      return {
        ...initialState,
        loading: true
      }
    }
    case 'SET_OTHER_COSTS': {
      return {
        ...initialState,
        otherCosts: action.otherCosts
      }
    }
    case 'SET_OTHER_COSTS_ERROR': {
      return {
        ...initialState,
        error: action.error
      }
    }
    default: return state
  }
}

export default reducer
