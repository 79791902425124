import React, { Component } from 'react'
import './Login.css'
import Label from '../../components/Label.js'
import Input from '../../components/Input.js'
import Text from '../../components/Text.js'
import Button from '../../components/Button.js'
import { setToken } from '../../token.js'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'
import { login } from '../../connector/connector.js'
import getUser from '../../connector/getUser.js'

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      fieldWithError: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (e) {
    e.preventDefault()
    const values = {
      username: this.state.username,
      password: this.state.password
    }
    const requiredFields = ['username', 'password']
    const missingValue = requiredFields.find(name => !values[name])
    if (missingValue) {
      return this.setState(state => ({ fieldWithError: missingValue }))
    }
    let response, errorCode
    try {
      response = await login(values)
    } catch (error) {
      console.log('login error', error)
      errorCode = await error.response ? error.response.text() : error.message
    }
    if (errorCode) {
      let fieldName
      switch (errorCode) {
        case 'err_invalid_user': {
          fieldName = 'username'
          break
        }
        case 'err_invalid_password': {
          fieldName = 'password'
          break
        }
        default: console.warn('unhandled error', errorCode)
      }
      return this.setState(state => ({ fieldWithError: fieldName }))
    }
    let seqpers
    try { // get seqpers
      const body = response.split('.')[1]
      const decoded = window.atob(body)
      const parsed = JSON.parse(decoded)
      seqpers = parsed.user
    } catch (error) {
      console.warn('couldn\'t get seqpers from token')
      window.alert('invalid token')
    }
    await getUser({ seqpers, token: response })
    setToken(response)
    history.push('/')
  }

  handleChange (name) {
    return e => {
      const newValue = e.target.value
      this.setState(state => ({
        [name]: newValue,
        fieldWithError: null
      }))
    }
  }

  render () {
    const t = this.props.t
    const fieldWithError = this.state.fieldWithError
    const usernameGroupClasses = ['Login-group']
    const passwordGroupClasses = ['Login-group']
    if (fieldWithError === 'username') {
      usernameGroupClasses.push('Login-group-error')
    } else if (fieldWithError === 'password') {
      passwordGroupClasses.push('Login-group-error')
    }
    return (
      <div className="Login">
        <form className="Login-form" onSubmit={this.handleSubmit}>
          <div className={usernameGroupClasses.join(' ')}>
            <Label><Text color="orange" weight="bold">{t('username')}</Text></Label>
            <Input onChange={this.handleChange('username')} />
          </div>
          <div className={passwordGroupClasses.join(' ')}>
            <Label><Text color="orange" weight="bold">{t('password')}</Text></Label>
            <Input onChange={this.handleChange('password')} type="password" />
          </div>
          <div className="Login-button-group">
            <div className="Login-button">
              <Button color="orange" type="submit">{t('log_in')}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const LoginWithT = withTranslation()(Login)

export default LoginWithT
