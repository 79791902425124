import { createStore, combineReducers } from 'redux'
import userReducer from './user/reducer.js'
import planningReducer from './planning/reducer.js'
import extraReducer from './extra/reducer.js'
import extraPositionsReducer from './extraPositions/reducer.js'
import positionsReducer from './positions/reducer.js'
import jobPageReducer from './jobPage/reducer.js'
import workProgressReducer from './workProgress/reducer.js'
import otherCostsReducer from './otherCosts/reducer.js'
import appUpdateReducer from './appUpdate/reducer.js'

const appReducer = combineReducers({
  user: userReducer,
  planning: planningReducer,
  extra: extraReducer,
  extraPositions: extraPositionsReducer,
  positions: positionsReducer,
  jobPage: jobPageReducer,
  workProgress: workProgressReducer,
  otherCosts: otherCostsReducer,
  lastUpdate: date => date || new Date(),
  appUpdate: appUpdateReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = {
      user: state.user,
      lastUpdate: new Date()
    }
  }
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }
  return appReducer(state, action)
}

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store

function reduxLogout () {
  store.dispatch({
    type: 'USER_LOGOUT'
  })
}

export { reduxLogout }
