import React, { Component } from 'react'
import './AddJobProgress.css'
import { connect } from 'react-redux'
import Header from '../../components/Header.js'
import Button from '../../components/Button.js'
import Text from '../../components/Text.js'
import Icon from '../../components/Icon.js'
import Input from '../../components/Input.js'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'
import { addWorkProgress } from '../../connector/connector.js'
import { getToken } from '../../token.js'
import Loader from '../../components/Loader.js'
import { addMessage } from '../../components/Messages.js'

class AddJobProgress extends Component {
  constructor (props) {
    super(props)

    const query = new URLSearchParams(window.location.search)
    const newDetail = query.get('newDetail') === 'true'

    this.state = {
      amount: 0,
      selectedAmount: 'all',
      selectedWorker: props.user.user,
      loading: false,
      newDetail
    }

    this.handleAmountChange = this.handleAmountChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleWorkerClick (worker) {
    return e => {
      this.setState(state => ({ selectedWorker: worker }))
    }
  }

  handleAmountChange (e) {
    const newValue = e.target.value
    this.setState(state => ({ amount: newValue }))
  }

  handleRadioClick (name) {
    return e => {
      this.setState(state => ({ selectedAmount: name }))
    }
  }

  async handleSave (e) {
    if (this.state.loading) return
    const createdBy = this.props.user.user.seqpers
    const werkDetail = this.props.werkDetail
    const planning = this.props.planning
    const {
      amount,
      selectedAmount,
      selectedWorker,
      newDetail
    } = this.state
    let progressAmount
    switch (selectedAmount) {
      case 'none': {
        return history.go(-3)
      }
      case 'all': {
        progressAmount = werkDetail.det_aantal
        break
      }
      case 'some': {
        progressAmount = amount
        break
      }
      default: return
    }
    this.setState(state => ({ loading: true }))
    try {
      await addWorkProgress({
        seqpers: selectedWorker.seqpers,
        createdBy,
        amount: progressAmount,
        idTWerkDetail: werkDetail.det_id,
        token: getToken(),
        seqwerk: planning.w_seqwerk,
        seqwerkdet: werkDetail.det_seqwerkdet
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }
    let message = 'amount_drilling_completed'
    if (werkDetail.det_typewerk === 'B') {
      message = 'amount_drilling_completed'
    }
    if (werkDetail.det_typewerk === 'Z') {
      message = 'amount_saw_completed'
    }
    addMessage({
      type: 'info',
      message: this.props.t(message, { num: progressAmount })
    })
    if (newDetail) {
      return history.go(-3)
    }
    return history.goBack()
  }

  render () {
    const {
      t,
      werkDetail
    } = this.props
    const workers = []
    if (!this.props.user.loading && this.props.user.user) {
      workers.push(this.props.user.user)
    }
    const {
      selectedWorker,
      selectedAmount,
      loading,
      amount,
      newDetail
    } = this.state

    const type = werkDetail.det_typewerk || 'B'
    let color = 'purple'
    if (type === 'B') {
      color = 'purple'
    }
    if (type === 'Z') {
      color = 'blue'
    }

    return (
      <div>
        { loading && <Loader fullPage={true} /> }
        <Header color={color} backButton={true}>
          <div className="AJP-header">
            <Text color="white" weight="bold" size="large">{werkDetail.det_aantal} {werkDetail.pos_positie_code} {werkDetail.det_diameter} {werkDetail.det_diepte} {t('done')}</Text>
            <Button onClick={history.goBack}><Text color="white">{t('cancel')}</Text></Button>
          </div>
        </Header>
        { workers.length > 1 &&
          <div className="AJP-workers">
            {workers.map(worker => {
              const isSelected = selectedWorker.seqpers === worker.seqpers
              const name = `${worker.voornaam} ${worker.achternaam}`
              return (
                <div key={worker.seqpers} className="AJP-worker-row" onClick={this.handleWorkerClick(worker)}>
                  <div className="AJP-worker-icon"><Icon name="worker" fill={isSelected ? `var(--${color})` : 'var(--grey-dark)'} /></div>
                  <Text color={isSelected ? color : 'dark'} weight={isSelected ? 'bold' : 'normal'}>{name}</Text>
                  { isSelected && <div className="AJP-worker-icon"><Icon name="check" fill={`var(--${color}])`} /></div> }
                </div>
              )
            })}
          </div>
        }
        <div className={`AJP-radio-container AJP-radio-container-${color}`}>
          { newDetail &&
            <div className="AJP-radio-row">
              <div className="AJP-radio" onClick={this.handleRadioClick('none')}>
                <div className="AJP-radio-circle">
                  { selectedAmount === 'none' && <div className="AJP-radio-ball"></div> }
                </div>
              </div>
              <div onClick={this.handleRadioClick('none')}><Text color="dark">{t('none')}</Text></div>
            </div>
          }
          <div className="AJP-radio-row">
            <div className="AJP-radio" onClick={this.handleRadioClick('all')}>
              <div className="AJP-radio-circle">
                { selectedAmount === 'all' && <div className="AJP-radio-ball"></div> }
              </div>
            </div>
            <div onClick={this.handleRadioClick('all')}><Text color="dark">{t('all_amount', { amount: werkDetail.det_aantal })}</Text></div>
          </div>
          <div className="AJP-radio-row">
            <div className="AJP-radio" onClick={this.handleRadioClick('some')}>
              <div className="AJP-radio-circle">
                { selectedAmount === 'some' && <div className="AJP-radio-ball"></div> }
              </div>
            </div>
            <div onClick={this.handleRadioClick('some')}><Text color="dark" onClick={this.handleRadioClick('some')}>{t('other_amount')}:</Text></div>
            <div className="AJP-radio-input" onClick={this.handleRadioClick('some')}><Input size="small" type="number" value={amount} onChange={this.handleAmountChange} /></div>
          </div>
        </div>
        <div className="AJP-save">
          <Button color="green" onClick={this.handleSave}>
            <div className="AJP-button">
              <Icon name="check" fill="white" width={16} height={16} />
              <Text color="white" weight="bold">{t('save')}</Text>
            </div>
          </Button>
        </div>
      </div>
    )
  }
}

const AddJobProgressWithT = withTranslation()(AddJobProgress)

function mapStateToProps (state, props) {
  const seqplan = props.seqplan
  const id = props.id
  const planning = (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan) || { werk_detail: [] }
  const werkDetail = planning.werk_detail.find(detail => detail.det_id === id) || {}
  return {
    werkDetail,
    user: state.user,
    planning
  }
}

const AddJobProgressConnect = connect(mapStateToProps)(AddJobProgressWithT)

export default AddJobProgressConnect
