import React from 'react'
import Header from '../../components/Header.js'
import Text from '../../components/Text.js'
import './Image.css'
import { connect } from 'react-redux'
import LoadImage from '../../components/LoadImage.js'

const Image = ({ image }) => {
  if (!image) return <div><Header backButton={true}><div className="Image-header"><Text>Er bestaat geen afbeelding met dit id.</Text></div></Header></div>
  return (
    <div>
      <Header backButton={true}>
        <div className="Image-header">
          <Text weight="bold" size="large" color="dark">{image.naam}</Text>
        </div>
      </Header>
      <div className="Image-container"><LoadImage seqafb={image.seqafb} large={true} /></div>
    </div>
  )
}

function mapStateToProps (state, props) {
  const { seqplan, seqafb } = props
  const planning = (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan) || {}
  return {
    image: (planning.images || []).find(image => image.seqafb === seqafb)
  }
}

const ImageConnect = connect(mapStateToProps)(Image)

export default ImageConnect
