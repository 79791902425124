import React from 'react'
import './UpdateMessage.css'
import i18n from '../i18n.js'
import Text from './Text.js'
import { connect } from 'react-redux'

const UpdateMessage = ({ show }) => {
  function handleUpdateClick () {
    window.location.reload()
  }

  const myClasses = [
    'UpdateMessage-root'
  ]
  if (show) {
    myClasses.push('UpdateMessage-visible')
  }

  return (
    <div className={myClasses.join(' ')}>
      <div className="UpdateMessage-slide">
        <Text color="white" size="large" weight="medium">{i18n.t('new_version_message')}</Text>
        <div className="UpdateMessage-button" onClick={handleUpdateClick}><Text size="normal" style={{ textTransform: 'uppercase', color: 'var(--green)' }} weight="bold">{i18n.t('refresh')}</Text></div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    show: state.appUpdate.show
  }
}

export default connect(mapStateToProps)(UpdateMessage)
