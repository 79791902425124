import React, { Component } from 'react'
import './Comment.css'
import Header from '../../components/Header.js'
import Text from '../../components/Text.js'
import Textarea from '../../components/Textarea.js'
import Button from '../../components/Button.js'
import Icon from '../../components/Icon.js'
import Loader from '../../components/Loader.js'
import history from '../../history.js'
import { withTranslation } from 'react-i18next'
import { createComment, updateComment, deleteComment, uploadFile, updateFile, deleteFile } from '../../connector/connector.js'
import { getToken } from '../../token.js'
import { connect } from 'react-redux'
import reloadPlanning from '../../reloadPlanning.js'
import { addMessage } from '../../components/Messages.js'
import Camera from '../../components/Camera.js'
import LoadImage from '../../components/LoadImage.js'

class Comment extends Component {
  constructor (props) {
    super(props)

    let editState = {}
    if (props.edit) {
      editState = this.createState()
    }

    this.state = {
      comment: '',
      pictures: [],
      loading: false,
      takePicture: false,
      ...editState
    }

    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.handleSelectClick = this.handleSelectClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.setPicture = this.setPicture.bind(this)

    this.fileRef = React.createRef()
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.edit && prevProps.planning !== this.props.planning) {
      this.setState(state => this.createState())
    }
  }

  createState () {
    const {
      seqopm,
      planning
    } = this.props
    if (!seqopm || !planning) return {}
    const myComment = planning.opmerkingen.find(comment => comment.seqopm === seqopm) || { images: [] }
    return {
      comment: myComment.opmerking,
      pictures: myComment.images.map(image => ({
        type: 'online',
        picture: image
      }))
    }
  }

  handleCommentChange (e) {
    const newValue = e.target.value
    this.setState(state => ({
      comment: newValue
    }))
  }

  handleSelectClick (e) {
    if (this.state.loading) return
    const input = this.fileRef.current
    if (!input) return
    this.fileRef.current.click()
  }

  handleCreateClick (e) {
    if (this.state.loading) return
    this.setState(state => ({ takePicture: true }))
  }

  async handleFileChange (e) {
    if (this.state.loading) return
    const file = e.target.files[0]
    if (!file) return

    this.setState(state => ({ loading: true }))

    const { planning, seqopm, user } = this.props
    const seqwerk = planning.w_seqwerk
    const seqpers = user.seqpers

    const filename = file.name
    const splitname = filename.split('.')
    const nameWithoutExtension = splitname.slice(0, -1).join('.')
    const finalFilename = [`${nameWithoutExtension}_opmerking_${seqwerk}_${seqpers}_${(planning.images || []).length + this.state.pictures.length}`, splitname.slice(-1)[0]].join('.')

    let response
    try {
      response = await uploadFile({
        file,
        filename: finalFilename,
        seqwerk,
        seqopm,
        seqpers,
        token: getToken()
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      this.setState(state => ({ loading: false }))
      return
    }

    const pictures = [...this.state.pictures]
    pictures.push({
      type: 'uploaded',
      picture: response
    })
    if (this.isUnmounting) return
    this.setState(state => ({
      pictures,
      loading: false
    }))
  }

  handleSaveClick () {
    if (this.state.loading) return
    if (this.props.edit) {
      this.editComment()
    } else {
      this.createComment()
    }
  }

  async createComment () {
    const {
      comment,
      pictures
    } = this.state
    const {
      planning,
      user
    } = this.props
    const token = getToken()
    const seqwerk = planning.w_seqwerk
    const seqpers = user.seqpers
    const existingImagesCount = (planning.images || []).length
    let commentResponse
    try {
      this.setState(state => ({ loading: true }))
      commentResponse = await createComment({
        comment: {
          opmerking: comment,
          seqwerk,
          seqpers
        },
        token
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }
    const seqopm = commentResponse.insertId

    // upload new pictures & add seqopm to previous file uploads
    const picturePromises = pictures.map((picture, i) => {
      if (picture.deleted) {
        return deleteFile({
          seqafb: picture.picture.insertId,
          token
        })
      } else if (picture.type === 'new') {
        return uploadFile({
          seqwerk,
          file: picture.picture,
          filename: `opmerking_${seqwerk}_${seqpers}_${seqopm}_${existingImagesCount + i}.png`,
          seqopm,
          seqpers,
          token
        })
      } else if (picture.type === 'uploaded') {
        return updateFile({
          seqafb: picture.picture.insertId,
          update: {
            seqopm,
            bij_opmerking: true
          },
          token
        })
      }
      return false
    })
    try {
      await Promise.all(picturePromises)
      await reloadPlanning()
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    addMessage({
      type: 'info',
      message: this.props.t('comment_added')
    })
    history.goBack()
  }

  async editComment () {
    const {
      seqopm,
      planning,
      user
    } = this.props
    const {
      comment,
      pictures
    } = this.state
    const token = getToken()
    const seqwerk = planning.w_seqwerk
    const seqpers = user.seqpers
    const existingImagesCount = (planning.images || []).length
    try {
      this.setState(state => ({ loading: true }))
      await updateComment({
        seqopm,
        update: {
          opmerking: comment
        },
        token
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    // upload any pictures of type 'new' & delete flagged files
    const picturePromises = pictures.map((picture, i) => {
      if (picture.deleted) {
        return deleteFile({
          seqafb: picture.picture.insertId || picture.picture.seqafb,
          token
        })
      } else if (picture.type === 'new') {
        return uploadFile({
          seqwerk,
          file: picture.picture,
          filename: `opmerking_${seqwerk}_${seqpers}_${seqopm}_${existingImagesCount + i}.png`,
          seqopm,
          seqpers,
          token
        })
      }
      return false
    })
    try {
      await Promise.all(picturePromises)
      await reloadPlanning()
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    addMessage({
      type: 'info',
      message: this.props.t('comment_edited')
    })
    history.goBack()
  }

  async handleDeleteClick () {
    const confirm = window.confirm(this.props.t('confirm_delete'))
    if (!confirm) return
    const seqopm = this.props.seqopm
    const { pictures } = this.state
    const token = getToken()
    try {
      this.setState(state => ({ loading: true }))
      await deleteComment({
        seqopm,
        token
      })
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    // delete all images
    const picturePromises = pictures.map((picture, i) => {
      if (picture.type === 'new') {
        return false
      } else {
        return deleteFile({
          seqafb: picture.picture.insertId || picture.picture.seqafb,
          token
        })
      }
    })
    try {
      await Promise.all(picturePromises)
      await reloadPlanning()
    } catch (error) {
      console.error(error)
      addMessage({
        type: 'error',
        message: error
      })
      return
    }

    addMessage({
      type: 'info',
      message: this.props.t('comment_deleted')
    })
    history.goBack()
  }

  setPicture (picture) {
    const pictures = [...this.state.pictures]
    pictures.push({
      type: 'new',
      picture
    })
    this.setState(state => ({
      takePicture: false,
      pictures
    }))
  }

  handlePictureDeleteClick (index) {
    return async e => {
      const pictures = this.state.pictures
      const myPicture = pictures[index]
      let newPictures
      switch (myPicture.type) {
        case 'online':
        case 'uploaded': {
          newPictures = pictures.map((picture, i) => {
            if (i === index) {
              return {
                ...picture,
                deleted: true
              }
            }
            return picture
          })
          break
        }
        case 'new': {
          newPictures = [...pictures.slice(0, index), ...pictures.slice(index + 1)]
          break
        }
        default: {
          console.warn(`unrecognised image type: ${myPicture.type}`)
          addMessage({
            type: 'error',
            message: this.props.t('unrecognised_image_type')
          })
          break
        }
      }
      this.setState(state => ({
        pictures: newPictures
      }))
    }
  }

  render () {
    const {
      t,
      edit,
      planning
    } = this.props
    const {
      comment,
      loading,
      takePicture,
      pictures
    } = this.state
    const isFinished = (planning || {}).p_uitgevoerd

    return (
      <div>
        {loading && <Loader fullPage={true} />}
        {takePicture && <Camera setPicture={this.setPicture} goBack={() => this.setState(state => ({ takePicture: false }))} />}
        <input className="Comment-file-input" ref={this.fileRef} type="file" onChange={this.handleFileChange} />
        <Header size="small" backButton={true} shadow={true}>
          <div className="Comment-header">
            <Text weight="bold" size="large">{edit ? t('edit_comment') : t('add_comment')}</Text>
            <Button weight="normal" onClick={history.goBack}>{t('cancel')}</Button>
          </div>
        </Header>
        <div className="Comment-body">
          <div className="Comment-scroll">
            <div className="Comment-input">
              <Textarea onChange={this.handleCommentChange} value={comment} disabled={!!isFinished} />
              {!isFinished &&
                <div className="Comment-buttons">
                  <Button onClick={this.handleSelectClick} color="orange">
                    <div className="Comment-button">
                      <Icon width={20} height={20} name="gallery" fill="white" />
                      <Text color="white" weight="bold">{t('choose_picture')}</Text>
                    </div>
                  </Button>
                  <Button onClick={this.handleCreateClick} color="orange">
                    <div className="Comment-button">
                      <Icon width={20} height={20} name="camera" fill="white" />
                      <Text color="white" weight="bold">{t('take_picture')}</Text>
                    </div>
                  </Button>
                </div>
              }
            </div>
            <div className="Comment-pictures">
              {pictures.map((picture, i) => {
                if (picture.deleted) return null
                return (
                  <div key={i} className="Comment-picture-container">
                    {picture.type === 'new'
                      ? <LoadImage blob={picture.picture} />
                      : <LoadImage seqafb={picture.picture.insertId || picture.picture.seqafb} />
                    }
                    {!isFinished &&
                      <div className="Comment-picture-delete">
                        <Button onClick={this.handlePictureDeleteClick(i)}>
                          <Icon name="delete" width={12} height={12} />
                        </Button>
                      </div>
                    }
                  </div>
                )
              })}
            </div>
          </div>
          <div className="Comment-save">
            {edit &&
              <Button color="red" onClick={this.handleDeleteClick} disabled={!!isFinished}>
                <div className="Comment-button">
                  <Icon name="delete" fill="white" />
                  <Text color="white" weight="bold">{t('delete')}</Text>
                </div>
              </Button>
            }
            <Button onClick={this.handleSaveClick} color="green" disabled={!!isFinished}>
              <div className="Comment-button">
                <Icon width={18} height={18} name="check" fill="white" />
                <Text color="white" weight="bold">{t('save')}</Text>
              </div>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const CommentWithT = withTranslation()(Comment)

function mapStateToProps (state, props) {
  const seqplan = props.seqplan
  return {
    planning: (state.planning.planning || []).find(planning => planning.p_seqplan === seqplan),
    user: state.user.user
  }
}

const CommentConnect = connect(mapStateToProps)(CommentWithT)

export default CommentConnect
